const overviewDisclosureConsentDetails = data => {  
    if (!Object.keys(data).length) {
      return null
    }
    return [
    {
      dt: 'I have read the Privacy Policy and I understand how DBS will process my personal data.',
      dd: data.DisclosureConsent && data.DisclosureConsent.DBSPrivacyPolicyConsent ? 'Yes' : 'No'
    },
    {
      dt: 'I agree to receive my e-result and share this with necessary third parties involved in the recruitment process.',
      dd: data.DisclosureConsent && data.DisclosureConsent.DBSResultThirdPartyConsent ? 'Yes' : 'No'
    },
    {
      dt: 'I agree that my data will be stored by Arnold Clark for 1 year.',
      dd: data.DisclosureConsent && data.DisclosureConsent.DBSArnoldClarkStoreConsent ? 'Yes' : 'No'
    }
  ]
  }
  export default overviewDisclosureConsentDetails
  