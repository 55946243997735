import axios from 'axios'
import auth from '../auth'

const baseApi = axios.create({
  baseURL: process.env.API_URL,
  timeout: 20000
})

baseApi.interceptors.response.use(
  config => config,
  error => {
    if (error.response.statusText === 'Token Has Expired') {
      auth.logout()
    }

    return Promise.reject(error)
  }
)

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.post.Accept = 'application/json'

const api = {
  addressLookup: postcode =>
    baseApi({
      method: 'GET',
      url: `/AddressLookup?postcode=${postcode}`
    }).then(res => res),

  authenticatedUser: {
    getAll: () =>
      baseApi({
        method: 'GET',
        url: '/AuthenticatedUser'
      }).then(res => res)
  },

  branch: {
    searchByTerm: term =>
      baseApi({
        method: 'POST',
        url: `/Branch/Search/${term}`
      }).then(res => res),

    getById: branchId =>
      baseApi({
        method: 'GET',
        url: `/Branch/${branchId}`
      }).then(res => res),

    getAll: () =>
      baseApi({
        method: 'GET',
        url: '/Branch'
      }).then(res => res)
  },

  lineManager: {
    getAll: () =>
      baseApi({
        method: 'GET',
        url: '/LineManager'
      })
  },

  contractType: {
    getAll: () =>
      baseApi({
        method: 'GET',
        url: '/ContractType'
      }).then(res => res)
  },

  contractReason: {
    getAll: () =>
      baseApi({
        method: 'GET',
        url: '/TemporaryContractReason/TemporaryContractReason_Get'
      }).then(res => res)
  },

  typeOfWork: {
    getAll: () =>
      baseApi({
        method: 'GET',
        url: '/WorkType/WorkType_Get'
      }).then(res => res)
  },

  country: {
    getAll: () =>
      baseApi({
        method: 'GET',
        url: '/Country',
        headers: auth.getAuthHeader()
      }).then(res => res)
  },

  nationality: {
    getAll: () =>
      baseApi({
        method: 'GET',
        url: '/Nationality',
        headers: auth.getAuthHeader()
      }).then(res => {
        // pull British to top of list
        const britishPos = res.data.map(nationality => nationality.Name).indexOf('British')
        const british = res.data.splice(britishPos, 1)
        return { ...res, data: [...british, ...res.data] }
      })
  },

  department: {
    searchByTerm: term =>
      baseApi({
        method: 'POST',
        url: `/Department/Search/${term}`
      }).then(res => res),

    getById: deptId =>
      baseApi({
        method: 'GET',
        url: `/Department/${deptId}`
      }).then(res => res),

    getAll: () =>
      baseApi({
        method: 'GET',
        url: '/Department'
      }).then(res => res)
  },

  documentCategory: {
    getAll: () =>
      baseApi({
        method: 'GET',
        url: '/DocumentCategory'
      }).then(res => res)
  },

  employee: {
    getById: employeeId =>
      baseApi({
        method: 'GET',
        url: `/Employee/${employeeId}`,
        headers: auth.getAuthHeader()
      }).then(res => res)
  },

  employeeAuth: {
    getAuthUrl: redirectUrl =>
      baseApi({
        method: 'GET',
        url: `/EmployeeAuthentication?redirectUrl=${redirectUrl}`
      }).then(res => res),

    sendLoginData: loginData =>
      baseApi({
        method: 'POST',
        url: `/EmployeeAuthentication?loginData=${loginData}`
      }).then(res => res)
  },

  exitReason: {
    searchByTerm: term =>
      baseApi({
        method: 'GET',
        url: `/NewStartExitReason/Search/${term}`
      }).then(res => res),

    getAll: () =>
      baseApi({
        method: 'GET',
        url: '/ExitReason'
      }).then(res => res)
  },

  feedback: {
    send: data =>
      baseApi({
        method: 'POST',
        url: `/Feedback?message=${data.message}`,
        headers: auth.getAuthHeader(),
        data
      }).then(res => res)
  },

  gender: {
    getAll: () =>
      baseApi({
        method: 'GET',
        url: '/Gender'
      }).then(res => res)
  },

  heartBeat: () =>
    baseApi({
      method: 'GET',
      url: '/HeartBeat'
    }).then(res => res),

  jobTitle: {
    searchByTerm: term =>
      baseApi({
        method: 'POST',
        url: `/JobTitle/Search/${term}`
      }).then(res => res),

    getById: jobTitleId =>
      baseApi({
        method: 'GET',
        url: `/JobTitle/${jobTitleId}`
      }).then(res => res),

    getAll: () =>
      baseApi({
        method: 'GET',
        url: '/JobTitle'
      }).then(res => res)
  },

  terms: {
    getAll: () =>
      baseApi({
        method: 'GET',
        url: '/TermsConditions/GetLetters',
        headers: auth.getAuthHeader()
      }).then(res => res),

    getById: id =>
      baseApi({
        method: 'GET',
        url: `/TermsConditions/GetLetter/${id}`,
        headers: auth.getAuthHeader()
      }).then(res => res),

    getByEmployeeId: employeeId =>
      baseApi({
        method: 'GET',
        url: `/TermsConditions/GetEmployee/${employeeId}`,
        headers: auth.getAuthHeader()
      }).then(res => res),

    postNewLetter: data =>
      baseApi({
        method: 'POST',
        url: '/TermsConditions/CreateOrUpdate',
        headers: auth.getAuthHeader(),
        data
      })
      .then(res => res),
    getConfirmLetter: transactionId =>
      baseApi({
        method: 'GET',
        url: `/TermsConditions/SendDocument/${transactionId}`,
        headers: auth.getAuthHeader()
      })
      .then(res => res),
    getDocument: documentId =>
      baseApi({
        method: 'GET',
        url: `/TermsConditions/GetDocument/${documentId}`,
        headers: auth.getAuthHeader()
      }).then(res => res)
  },

  newStart: {
    getById: id =>
      baseApi({
        method: 'GET',
        url: `/NewStart/${id}`,
        headers: auth.getAuthHeader()
      }).then(res => res),

    getByRegion: regionId =>
      baseApi({
        method: 'GET',
        url: `/NewStart/GetByRegion/${regionId}`,
        headers: auth.getAuthHeader()
      }).then(res => res),

    sendInvite: userId =>
      baseApi({
        method: 'POST',
        url: `/NewStart/SendInvite/${userId}`,
        headers: auth.getAuthHeader()
      }).then(res => res),

    getInviteLink: userId =>
      baseApi({
        method: 'GET',
        url: `/NewStart/ViewInvite/${userId}`,
        headers: auth.getAuthHeader()
      }).then(res => res),

    getAll: () =>
      baseApi({
        method: 'GET',
        url: '/NewStart',
        headers: auth.getAuthHeader()
      }).then(res => res)
  },

  newStartAddress: {
    getByNewStartId: userId =>
      baseApi({
        method: 'GET',
        url: `/NewStartAddresses/${userId}`,
        headers: auth.getAuthHeader()
      }).then(res => res),

    getAll: () =>
      baseApi({
        method: 'GET',
        url: '/NewStartAddress',
        headers: auth.getAuthHeader()
      }).then(res => res),

    createNew: data =>
      baseApi({
        method: 'POST',
        url: '/NewStartAddress',
        headers: auth.getAuthHeader(),
        data
      }).then(res => res)
  },

  newStartAuth: {
    setPassword: password =>
      baseApi({
        method: 'POST',
        url: '/NewStartAuthentication/SetPassword',
        headers: auth.getAuthHeader(),
        data: {
          Password: password
        }
      }).then(res => res),

    unlock: id =>
      baseApi({
        method: 'POST',
        url: `/NewStartAuthentication/Unlock`,
        data: id,
        headers: {
          ...auth.getAuthHeader(),
          'Content-Type': 'application/json'
        }
      }).then(res => res),

    login: (email, password) =>
      baseApi({
        method: 'POST',
        url: '/NewStartAuthentication',
        data: {
          Email: email,
          Password: password
        }
      }).then(res => res)
  },

  newStartContactPrefs: {
    getById: userId =>
      baseApi({
        method: 'GET',
        url: `/NewStartContactPreferences/${userId}?newStartUserId=${userId}`,
        headers: auth.getAuthHeader()
      }).then(res => res),

    getAll: () =>
      baseApi({
        method: 'GET',
        url: '/NewStartContactPreferences',
        headers: auth.getAuthHeader()
      }).then(res => res),

    setPrefs: data =>
      baseApi({
        method: 'POST',
        url: '/NewStartContactPreferences',
        headers: auth.getAuthHeader(),
        data
      }).then(res => res)
  },

  newStartMedicalInsurance: {
    getDependents: userId =>
      baseApi({
        method: 'GET',
        url: `/NewStartMedicalInsurance/${userId}`,
        headers: auth.getAuthHeader()
      }).then(res => res),

    joinAxaHealthCare: data =>
      baseApi({
        method: 'POST',
        url: '/NewStartMedicalInsurance',
        headers: auth.getAuthHeader(),
        data
      }).then(res => res)
  },

  newStartHandArmVibration: {
    getNewStartHandArmVibration: userId =>
      baseApi({
        method: 'GET',
        url: `/NewStartHandArmVibration/${userId}`,
        headers: auth.getAuthHeader()
      }).then(res => res),

    addNewStartHandArmVibration: data =>
      baseApi({
        method: 'POST',
        url: '/NewStartHandArmVibration',
        headers: auth.getAuthHeader(),
        data
      }).then(res => res)
  },

  newStartDisclosure: {
    getByUserId: userId =>
      baseApi({
        method: 'GET',
        url: `/NewStartDisclosure/${userId}`,
        validateStatus: status => (status >= 200 && status < 300) || status === 404,
        headers: auth.getAuthHeader()
      }).then(res => res),

    addDetails: data =>
      baseApi({
        method: 'POST',
        url: '/NewStartDisclosure',
        headers: auth.getAuthHeader(),
        data
      }).then(res => res)
  },

  newStartDocument: {
    getByTypeIdAndUserId: (typeId, userId) =>
      baseApi({
        method: 'GET',
        url: `/GetDocuments/${typeId}/${userId}`,
        headers: auth.getAuthHeader()
      }).then(res => res),

    getByUserId: userId =>
      baseApi({
        method: 'GET',
        url: `/GetAllDocumentsForUser/${userId}`,
        headers: auth.getAuthHeader()
      }).then(res => res),

    deleteByDocId: docId =>
      baseApi({
        method: 'DELETE',
        url: `NewStartDocument/${docId}`,
        headers: auth.getAuthHeader()
      }).then(res => res),

    getByDocId: docId =>
      baseApi({
        method: 'GET',
        url: `/NewStartDocument/${docId}`,
        headers: auth.getAuthHeader()
      }).then(res => res),

    addNewDoc: data =>
      baseApi({
        method: 'POST',
        url: '/NewStartDocument',
        headers: auth.getAuthHeader(),
        data
      }).then(res => res),

    updateDoc: data =>
      baseApi({
        method: 'PUT',
        url: '/NewStartDocument',
        headers: auth.getAuthHeader(),
        data
      }).then(res => res),

    sign: docId =>
      baseApi({
        method: 'POST',
        url: `/Sign/${docId}`,
        headers: auth.getAuthHeader()
      }).then(res => res),

    emailDocuments: userId =>
      baseApi({
        method: 'POST',
        url: `/Email/${userId}`,
        headers: auth.getAuthHeader()
      }).then(res => res)
  },

  newStartLifeAssurance: {
    getByUserId: userId =>
      baseApi({
        method: 'GET',
        url: `/NewStartLifeAssurance/${userId}`,
        headers: auth.getAuthHeader()
      }).then(res => res),

    addBeneficiaries: data =>
      baseApi({
        method: 'POST',
        url: '/NewStartLifeAssurance',
        headers: auth.getAuthHeader(),
        data
      }).then(res => res),

    deleteBeneficiary: id =>
      baseApi({
        method: 'DELETE',
        url: `NewStartLifeAssurance?beneficiaryId=${id}`,
        headers: auth.getAuthHeader()
      }).then(res => res)
  },

  newStartExit: {
    abort: data =>
      baseApi({
        method: 'POST',
        url: '/NewStartExit/Abort',
        headers: auth.getAuthHeader(),
        data
      }).then(res => res),

    complete: data =>
      baseApi({
        method: 'POST',
        url: '/NewStartExit/Complete',
        headers: auth.getAuthHeader(),
        data
      }).then(res => res)
  },

  newStartMagicLinkAuth: magicLinkToken =>
    baseApi({
      method: 'GET',
      url: `/NewStartMagicLinkEmailAuthentication?magicLinkToken=${magicLinkToken}`
    }).then(res => res),

  newStartMedicalForm: {
    getByUserId: userId =>
      baseApi({
        method: 'GET',
        url: `/NewStartMedicalInformation/${userId}`,
        validateStatus: status => (status >= 200 && status < 300) || status === 404,
        headers: auth.getAuthHeader()
      }).then(res => res),

    addDetails: data =>
      baseApi({
        method: 'POST',
        url: '/NewStartMedicalForm',
        headers: auth.getAuthHeader(),
        data
      }).then(res => res)
  },

  newStartApprentice: {
    getByUserId: userId =>
      baseApi({
        method: 'GET',
        url: `/NewStartApprenticeForm/${userId}`,
        headers: auth.getAuthHeader()
      }).then(res => res),

    addDetails: data =>
      baseApi({
        method: 'POST',
        url: 'NewStartApprenticeForm/',
        headers: auth.getAuthHeader(),
        data
      }).then(res => res)
  },

  newStartPersonalDetails: {
    addDetails: data =>
      baseApi({
        method: 'POST',
        url: '/NewStartPersonalDetails',
        headers: auth.getAuthHeader(),
        data
      }).then(res => res),

    updateDetails: data =>
      baseApi({
        method: 'PUT',
        url: '/NewStartPersonalDetails',
        headers: auth.getAuthHeader(),
        data
      }).then(res => res)
  },

  newStartPositionDetails: {
    updateDetails: data =>
      baseApi({
        method: 'PUT',
        url: '/NewStartPositionDetails',
        headers: auth.getAuthHeader(),
        data
      }).then(res => res)
  },

  newStartStep: {
    getByUserId: userId =>
      baseApi({
        method: 'GET',
        url: `/NewStartStep/${userId}`,
        headers: auth.getAuthHeader()
      }).then(res => res),

    complete: stepId =>
      baseApi({
        method: 'POST',
        url: `/NewStartStep/Complete?userStepId=${stepId}`,
        headers: auth.getAuthHeader()
      }).then(res => res)
  },

  newStartWageInformation: {
    getByUserId: userId =>
      baseApi({
        method: 'get',
        url: `/NewStartWageInformation/${userId}`,
        validateStatus: status => (status >= 200 && status < 300) || status === 404,
        headers: auth.getAuthHeader()
      }).then(res => res),

    addWageInfo: data =>
      baseApi({
        method: 'post',
        url: '/NewStartWageInformation',
        headers: auth.getAuthHeader(),
        data
      }).then(res => res)
  },

  passwordRequest: {
    sendEmailAddress: emailAddress =>
      baseApi({
        method: 'POST',
        url: '/PasswordRequest/GetPasswordRequestEmail',
        data: { emailAddress }
      }).then(res => res),

    sendToken: token =>
      baseApi({
        method: 'POST',
        url: '/PasswordRequest',
        data: { token }
      }).then(res => res)
  },

  workingLocationCategory: {
    getAll: () =>
      baseApi({
        method: 'GET',
        url: '/WorkingLocationCategory'
      }).then(res => res)
  },

  region: {
    getAll: () =>
      baseApi({
        method: 'GET',
        url: '/Region'
      }).then(res => res)
  },

  step: {
    getById: stepId =>
      baseApi({
        method: 'GET',
        url: `/GetStep/${stepId}`
      }).then(res => res),

    getAll: () =>
      baseApi({
        method: 'GET',
        url: '/Step'
      }).then(res => res),

    createNewStep: data =>
      baseApi({
        method: 'POST',
        url: '/Step',
        data: { ...data }
      }).then(res => res)
  },

  title: {
    getAll: () =>
      baseApi({
        method: 'GET',
        url: '/Title'
      }).then(res => res)
  },

  wagesExport: {
    get: () =>
      baseApi({
        method: 'GET',
        url: '/WagesExport',
        headers: auth.getAuthHeader()
      }).then(res => res)
  },

  medicalInsuranceExport: {
    get: () =>
      baseApi({
        method: 'GET',
        url: '/MedicalInsuranceExport',
        headers: auth.getAuthHeader()
      }).then(res => res)
  },

  apprenticeFormExport: {
    get: () =>
      baseApi({
        method: 'GET',
        url: '/ApprenticeFormExport',
        headers: auth.getAuthHeader()
      }).then(res => res)
  },

  havsFormExport: {
    get: () =>
      baseApi({
        method: 'GET',
        url: '/HavsFormExport',
        headers: auth.getAuthHeader()
      }).then(res => res)
  },

  report: {
    medicalInsuranceReportList: data =>
    baseApi({
      method: 'POST',
      url: '/MedicalInsuranceReport/GetList',
      headers: auth.getAuthHeader(),
      data
    }).then(res => res)
    ,
    medicalInsuranceReportCsv: data =>
    baseApi({
      method: 'POST',
      url: '/MedicalInsuranceReport/GetCsv',
      headers: auth.getAuthHeader(),
      data
    }).then(res => res)
  }
}

export default api
