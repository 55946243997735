import moment from 'moment'
import { UserTypes } from '../../../../config/UserTypes'

const overviewPositionDetails = data => {
  if (!Object.keys(data).length) {
    return null
  }
  return [
    {
      dt: 'Position',
      dd: data.JobTitleName
    },
    {
      dt: 'Branch',
      dd: data.BranchName
    },
    {
      dt: 'Department',
      dd: data.DepartmentName
    },
    {
      dt: 'Working Location Category',
      dd: data.WorkingLocationCategoryName
    },  
    {
      dt: 'Manager name',
      dd: data.ManagerName
    },
    {
      dt: 'Contract type',
      dd: data.ContractTypeName
    },
    {
      dt: 'Type of work',
      dd: data.WorkTypeName
    },
    {
      dt: data.TemporaryContractReasonName && 'Contract reason',
      dd: data.TemporaryContractReasonName
    },
    {
      dt: data.TemporaryContractExpiryDate && 'Temporary contract expiry date',
      dd: data.TemporaryContractExpiryDate && moment.utc(data.TemporaryContractExpiryDate).format('DD/MM/YYYY')
    },
    {
      dt: 'Start date',
      dd: moment.utc(data.StartDate).format('DD/MM/YYYY')
    },
    {
      dt: 'Hours of work',
      dd: data.HoursOfWork
    },
    {
      dt: 'Flexitime',
      dd: data.Flexitime === true ? 'Yes' : 'No'
    },
    {
      dt: 'Company Car',
      dd: data.CompanyCar === true ? 'Yes' : 'No'
    },
    {
      dt: 'Is Apprentice',
      dd: data.UserType === UserTypes.APPRENTICE ? 'Yes' : 'No'
    }
  ]
}
export default overviewPositionDetails
