import React from 'react'
import { Router } from '@reach/router'

import Contracts from './NewStart/Contracts'
import Dashboard from './Dashboard'
import MedicalInsuranceReport from './Reports/MedicalInsurance'
import Terms from './Terms'
import Letters from './Terms/Letters'
import NewStart from './NewStart'
import Overview from './NewStart/Overview'
import TermsOverview from './Terms/TermsOverview'
import PersonalDetails from './NewStart/PersonalDetails'
import PositionDetails from './NewStart/PositionDetails'
import Delete from './NewStart/Delete'
import ViewInvite from './NewStart/ViewInvite'

import DisclosureRoot from '../UserPortal/Steps/Disclosure'
import DisclosureAdditionalInformation from '../UserPortal/Steps/Disclosure/DisclosureAdditionalInformation'
import DisclosureBirthDetails from '../UserPortal/Steps/Disclosure/DisclosureBirthDetails'
import DisclosureContactInformation from '../UserPortal/Steps/Disclosure/DisclosureContactInformation'
import DisclosurePersonalDetails from '../UserPortal/Steps/Disclosure/DisclosurePersonalDetails'

import AuthGate from '../../components/AuthGate'
import EmployeeDetails from './Terms/EmployeeDetails'
import accessRole from '../../services/accessRole'

const Admin = () => (
  <AuthGate adminAccess>
    <Router>
      <Dashboard path="/" /> 
      {accessRole.isMedicalInsuranceViewer() && (
      <MedicalInsuranceReport path="/reports/medical-insurance" />
      )}
      
      <Terms path="/terms">
        <EmployeeDetails path="/" />
        <EmployeeDetails path="/:letterId" isEditing />
        <Letters path="/list" />
        <TermsOverview path="/:id/overview" />
        <TermsOverview path="/:id/confirm" isConfirming />
      </Terms>
      
      <NewStart path="/new-start">
        <PersonalDetails path="/" />
        <PersonalDetails path="/:id" isEditing />
        <PositionDetails path="/:id/position-details" />
        <Contracts path="/:id/contracts" />
        <Overview path="/:id/overview" />
        <Delete path="/:id/delete" />
        <ViewInvite path="/:id/viewInvite" />

        <DisclosureRoot path="/:id/disclosure">
          <DisclosurePersonalDetails path="/personal-details" />
          <DisclosureBirthDetails path="/birth-details" />
          <DisclosureAdditionalInformation path="/additional-information" />
          <DisclosureContactInformation path="/contact-information" />
        </DisclosureRoot>
      </NewStart>
    </Router>
  </AuthGate>
)

export default Admin
