import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from '@reach/router'
import { Button, Form, Text, margin, position, width } from 'chassis-react'
import moment from 'moment'
import auth from '../../../auth'
import api from '../../../api'
import valueFormat from '../../../utilities/valueFormat'
import ContentLoader from '../../../components/ContentLoader'
import ContentWrap from '../../../components/ContentWrap'
import FormDivider from '../../../components/FormDivider'
import FormTextInput from '../../../components/FormTextInput'
import FormSearchInput from '../../../components/FormSearchInput'
import FormWrapper from '../../../components/FormWrapper'
import Layout from '../../../components/Layout'
import { employeeDetailsSchema } from './schema'
import getErrorForField from '../../../utilities/getErrorForField'
import focusOnError from '../../../utilities/focusOnError'
import handleBlur from '../../../utilities/handleBlur'

import './style.scss'

const EmployeeDetails = ({ letterId }) => {
  const [loaded, setLoaded] = useState(false)
  const [formData, setFormData] = useState({})
  const [employeeData, setEmployeeData] = useState()
  const [ValidationErrors, setValidationErrors] = useState([])
  const [loggedInUserDetails, setLoggedInUserDetails] = useState(false)
  const [isChecked, setIsChecked] = useState(true)

  useEffect(() => {
    const { Id: loggedUserId } = auth.getAppData()

    api.terms.getByEmployeeId(loggedUserId).then(({ data }) => {
      setLoggedInUserDetails(data)
    })

    if (letterId) {
      api.terms.getById(letterId).then(({ data }) => {
        setFormData({
          EmployeeId: data ? data.EmployeeId : '',
          EmployeeFirstname: data ? data.EmployeeFirstname : '',
          EmployeeSurname: data ? data.EmployeeSurname : '',
          EmployeeJobTitle: data ? data.EmployeeJobTitle : '',
          EmployeeWorkHours: data ? data.EmployeeWorkHours : '',
          EmployeeHiringManager: data ? data.EmployeeHiringManager : '',
          EmployeeSalary: data ? data.EmployeeSalary : '',
          EmployeeLocation: data ? data.EmployeeLocation : '',
          SenderFirstname: data ? data.SenderFirstname : '',
          SenderSurname: data ? data.SenderSurname : '',
          SenderJobTitle: data ? data.SenderJobTitle : '',
          DateEffectiveFrom: data ? moment.utc(data.DateEffectiveFrom).format('YYYY-MM-DD') : '',
          SenderToggle: isChecked
        })
        setEmployeeData(data)
      })
    }

    setLoaded(true)
  }, [])

  useEffect(() => {
    if (isChecked) {
      setFormData({
        ...formData,
        SenderToggle: true,
        SenderFirstname: loggedInUserDetails && loggedInUserDetails.EmployeeFirstname,
        SenderSurname: loggedInUserDetails && loggedInUserDetails.EmployeeSurname,
        SenderJobTitle: ''
      })
    } else {
      setFormData({
        ...formData,
        SenderToggle: false,
        SenderFirstname: formData && formData.EmployeeFirstname,
        SenderSurname: formData && formData.EmployeeSurname,
        SenderJobTitle: (formData && formData.SenderJobTitle) || ''
      })
    }
  }, [isChecked])

  const handleSubmit = e => {
    e.preventDefault()

    const testData = {
      Id: letterId,
      EmployeeId: +formData.EmployeeId,
      EmployeeFirstname: employeeData.EmployeeFirstname,
      EmployeeSurname: employeeData.EmployeeSurname,
      EmployeeJobTitle: formData.EmployeeJobTitle,
      EmployeeWorkHours: formData.EmployeeWorkHours,
      EmployeeHiringManager: formData.EmployeeHiringManager,
      EmployeeSalary: formData.EmployeeSalary,
      EmployeeLocation: formData.EmployeeLocation,
      DateEffectiveFrom: moment.utc(formData.DateEffectiveFrom),
      SenderToggle: isChecked,
      SenderFirstname: !isChecked ? formData.SenderFirstname : loggedInUserDetails.EmployeeFirstname,
      SenderSurname: !isChecked ? formData.SenderSurname : loggedInUserDetails.EmployeeSurname,
      SenderJobTitle: formData.SenderJobTitle || ''
    }

    employeeDetailsSchema
      .validate(formData, { abortEarly: false })
      .then(() =>
        api.terms.postNewLetter(testData).then(responses => {
          const { data } = responses
          navigate(`/admin/terms/${data.Id}/confirm/`)
          setLoaded(true)
        })
      )
      .catch(error => {
        setValidationErrors(error.inner)
        focusOnError()
      })
  }

  const handleChange = e => {
    const {
      name,
      value,
      dataset: { format }
    } = e.target

    setFormData({ ...formData, [name]: valueFormat(format, value) })
  }

  const handleSenderDetailsClick = bool => {
    setIsChecked(!bool)
  }

  const handleEmployeeIdChange = e => {
    const {
      name,
      value,
      dataset: { format }
    } = e.target

    setEmployeeData(false)
    setFormData({ ...formData, [name]: valueFormat(format, value) })
  }

  const handleEmployeeIdLookup = e => {
    e.preventDefault()

    api.terms
      .getByEmployeeId(+formData.EmployeeId)
      .then(({ data }) => {
        setEmployeeData(data)
        setFormData({
          ...formData,
          SenderFirstname: loggedInUserDetails.EmployeeFirstname,
          SenderSurname: loggedInUserDetails.EmployeeSurname,
          SenderJobTitle: loggedInUserDetails.EmployeeJobTitle
        })
      })
      .catch(serverError => {
        const is404Status = serverError.response.status === 404

        if (is404Status) {
          setValidationErrors([
            {
              path: 'EmployeeId',
              message: serverError.response.data
            }
          ])
        }
      })
  }

  return loaded ? (
    <Layout>
      <ContentWrap util={margin.t6}>
        <Text p>
          <Link to="/admin/terms/list">&lt; Back to internal T &amp; C letters</Link>
        </Text>
        <Text h2>Sending an internal T&amp;C letter</Text>

        <form onSubmit={handleSubmit}>
          <FormWrapper>
            <Text h3>Employee details</Text>

            <Form label>Search for Employee</Form>

            <FormSearchInput
              name="EmployeeId"
              type="text"
              value={formData.EmployeeId || ''}
              placeholder="Employee ID"
              onChange={handleEmployeeIdChange}
              onClick={handleEmployeeIdLookup}
              error={getErrorForField('EmployeeId', ValidationErrors)}
              onBlur={e => handleBlur(e, employeeDetailsSchema, formData, setValidationErrors)}
            />

            {employeeData && (
              <>
                <FormTextInput
                  name="EmployeeFirstname"
                  type="text"
                  label="First name"
                  value={employeeData.EmployeeFirstname}
                  readOnly
                  util={width.w6Sm}
                />
                <FormTextInput
                  name="EmployeeSurname"
                  type="text"
                  label="Surname"
                  value={employeeData.EmployeeSurname}
                  readOnly
                  util={width.w6Sm}
                />

                <FormTextInput
                  name="EmployeeJobTitle"
                  type="text"
                  label="Job Title"
                  value={formData.EmployeeJobTitle || ''}
                  onChange={handleChange}
                  util={width.w6Sm}
                  error={getErrorForField('EmployeeJobTitle', ValidationErrors)}
                  onBlur={e => handleBlur(e, employeeDetailsSchema, formData, setValidationErrors)}
                />

                <FormTextInput
                  name="DateEffectiveFrom"
                  type="date"
                  label="Date effective from"
                  value={formData.DateEffectiveFrom}
                  onChange={handleChange}
                  util={width.w4Sm}
                  placeholder="dd/mm/yyyy"
                  pattern="\d{4}-\d{2}-\d{2}"
                  error={getErrorForField('DateEffectiveFrom', ValidationErrors)}
                  onBlur={e => handleBlur(e, employeeDetailsSchema, formData, setValidationErrors)}
                />

                <FormTextInput
                  name="EmployeeWorkHours"
                  type="text"
                  label="Working hours"
                  value={formData.EmployeeWorkHours || ''}
                  onChange={handleChange}
                  util={width.w6Sm}
                  textarea
                  error={getErrorForField('EmployeeWorkHours', ValidationErrors)}
                  onBlur={e => handleBlur(e, employeeDetailsSchema, formData, setValidationErrors)}
                />
                <FormTextInput
                  name="EmployeeHiringManager"
                  type="text"
                  label="Hiring manager"
                  value={formData.EmployeeHiringManager || ''}
                  onChange={handleChange}
                  util={width.w6Sm}
                  error={getErrorForField('EmployeeHiringManager', ValidationErrors)}
                  onBlur={e => handleBlur(e, employeeDetailsSchema, formData, setValidationErrors)}
                />
                <FormTextInput
                  name="EmployeeSalary"
                  type="text"
                  label="Salary"
                  value={formData.EmployeeSalary || ''}
                  onChange={handleChange}
                  util={width.w6Sm}
                  error={getErrorForField('EmployeeSalary', ValidationErrors)}
                  onBlur={e => handleBlur(e, employeeDetailsSchema, formData, setValidationErrors)}
                />
                <FormTextInput
                  name="EmployeeLocation"
                  type="text"
                  label="Location"
                  value={formData.EmployeeLocation || ''}
                  onChange={handleChange}
                  util={width.w6Sm}
                  error={getErrorForField('EmployeeLocation', ValidationErrors)}
                  onBlur={e => handleBlur(e, employeeDetailsSchema, formData, setValidationErrors)}
                />
              </>
            )}

            <FormDivider />

            <Text h3>Sender details</Text>

            <Form
              name="SenderToggle"
              checkbox
              defaultChecked={isChecked}
              onClick={() => handleSenderDetailsClick(isChecked)}
            >
              Use my details as the letter sender.
            </Form>

            {!isChecked && (
              <div style={{ marginTop: 12 }}>
                <FormTextInput
                  name="SenderFirstname"
                  type="text"
                  label="Sender's first name"
                  value={formData.SenderFirstname || ''}
                  onChange={handleChange}
                  util={width.w6Sm}
                  error={getErrorForField('SenderFirstname', ValidationErrors)}
                  onBlur={e => handleBlur(e, employeeDetailsSchema, formData, setValidationErrors)}
                />

                <FormTextInput
                  name="SenderSurname"
                  type="text"
                  label="Sender's surname"
                  value={formData.SenderSurname || ''}
                  onChange={handleChange}
                  util={width.w6Sm}
                  error={getErrorForField('SenderSurname', ValidationErrors)}
                  onBlur={e => handleBlur(e, employeeDetailsSchema, formData, setValidationErrors)}
                />

                <FormTextInput
                  name="SenderJobTitle"
                  type="text"
                  label="Sender's Job Title"
                  value={formData.SenderJobTitle || ''}
                  onChange={handleChange}
                  util={width.w6Sm}
                  error={getErrorForField('SenderJobTitle', ValidationErrors)}
                  onBlur={e => handleBlur(e, employeeDetailsSchema, formData, setValidationErrors)}
                />
              </div>
            )}
          </FormWrapper>
          <Button success type="submit" util={position.floatRight}>
            Next: Confirm details
          </Button>
        </form>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

EmployeeDetails.propTypes = {
  letterId: PropTypes.string
}

export default EmployeeDetails
