const FILLER_CHARACTER = '9'

/**
 * Validates the licence number passed in against the regex found here:
 * https://www.etl-tools.com/regular-expressions/is-uk-driver-licence.html
 *
 * @param {string} licenceNumber - the number to be checked
 * @returns {boolean} boolean
 */
export const isValidUkDrivingLicence = licenceNumber => {
  if (!licenceNumber) return null

  const regex = /^[A-Z0-9]{5}\d[0156]\d([0][1-9]|[12]\d|3[01])\d[A-Z0-9]{3}[A-Z0-9]{2}$/

  return regex.test(licenceNumber)
}

/**
 * Takes a driving licence number in UK format and returns
 * an array with date information. Takes into account the differences
 * between how male / female details are stored.
 *
 * @param {string} licenceNumber
 * @returns {Array.<string>} Array formatted ['dd', 'mm', 'yy']
 */
export const extractDobFromDrivingLicence = licenceNumber => {
  if (!licenceNumber) return null
  if (!isValidUkDrivingLicence(licenceNumber)) return false

  // extracts the date of birth information from the driving licence number as a 6 digit string
  const dobInfo = licenceNumber.slice(5, 11)

  // extract each segment of the date
  const day = dobInfo.slice(3, 5)

  // The first digit of the month number is increased by 5 if the holder is female
  let month = parseInt(dobInfo.slice(1, 3), 10)
  month = month > 12 ? month - 50 : month

  // we also need to pad the month with zero if < 10 and convert to string
  month = month < 10 ? `0${month}` : month.toString()

  const year = dobInfo[0] + dobInfo[5]

  return [day, month.toString(), year]
}

/**
 * Validates a UK driving licence number against a date of birth.
 * Returns true if the candidate's day / month / year of birth matches
 * what is on their driving licence, and otherwise, false.
 *
 * (Will also return `null` if dateOfBirth or licenceNumber is falsy)
 *
 * @param {string} dateOfBirth
 * @param {string} licenceNumber
 * @returns {boolean} true / false
 */
export const validateDrivingLicenceAgainstDob = (dateOfBirth, licenceNumber) => {
  if (!dateOfBirth || !licenceNumber) return null

  const dobFromLicence = extractDobFromDrivingLicence(licenceNumber)

  // slicing from 2 - 10 to get the date info from the user, disregarding the century
  // From 1980-08-26T23:00:00 > ['26', '08', '80']
  const dobFromUser = dateOfBirth
    .slice(2, 10)
    .split('-')
    .reverse()

  return JSON.stringify(dobFromUser) === JSON.stringify(dobFromLicence)
}
// Method to add characters to string if the surname of the user is less than 5 characters
export const addCharsToSurname = (surname, howManyTimes) =>
  surname + new Array(howManyTimes).fill(FILLER_CHARACTER).join('')

/**
// Validate a UK driving license number against a users surname.
// Returns true if the first 5 characters of the number match the first 5 characters of the surname
* @param {string} licenceNumber 
* @param {string} surnameFromUser 
* @returns {boolean} true / false
*/
export const validateDrivingLicenseAgainstSurname = (licenceNumber, surnameFromUser) => {
  if (!surnameFromUser || !licenceNumber) return null
  const MAX_LENGTH_OF_SURNAME = 5

  // Get first 5 characters of license number and remove any whitespace
  const surnameFromLicense = licenceNumber.trim().slice(0, 5)

  // remove any whitespace and hyphens in case of double barrelled names
  let surnameFromUserToCompare = surnameFromUser
  // eslint-disable-next-line no-useless-escape
  const replaceHyphenRegExpress = /\-/g
  const replaceWhiteSpace = /\s/g
  surnameFromUserToCompare = surnameFromUser.replace(replaceHyphenRegExpress, '').replace(replaceWhiteSpace, '')

  // Check if surname starts with Mac, if so convert to Mc for comparison
  surnameFromUserToCompare = surnameFromUserToCompare
    .slice(0, 3)
    .toUpperCase()
    .includes('MAC')
    ? surnameFromUserToCompare.toUpperCase().replace('A', '').slice(0, 5)
    : surnameFromUserToCompare.slice(0, 5)

  // Check if user surname is fewer than 5 characters, if so add 9's
  surnameFromUserToCompare =
    surnameFromUserToCompare.length < MAX_LENGTH_OF_SURNAME
      ? addCharsToSurname(surnameFromUserToCompare, MAX_LENGTH_OF_SURNAME - surnameFromUserToCompare.length)
      : surnameFromUserToCompare

  return surnameFromLicense.toUpperCase() === surnameFromUserToCompare.toUpperCase()
}

/**
// Validate a UK driving license number against a users first name and any middle names.
* @param {string} licenceNumber 
* @param {string} firstNameFromUser 
* @param {string} middleNamesFromUser 
* @returns {boolean} true / false
*/

export const validateDrivingLicenseAgainstOtherNames = (
  licenceNumber,
  firstNameFromUser,
  middleNamesFromUser
) => {
  if (!licenceNumber || !firstNameFromUser) return null

  // Get characters from licenseNumber to validate against
  const otherNameCharactersFromLicense = licenceNumber.trim().slice(11, 13)
  // Get first initial from first name
  const firstCharacterOfUserFirstName = firstNameFromUser.trim().slice(0, 1)

  // Check for middle name(s) and trim whitespace
  const middleNamesFromUserToCompare = middleNamesFromUser ? middleNamesFromUser.trim() : null

  // Check if middle to compare exist and define what characters to use for comparison
  const charactersForComparison = middleNamesFromUserToCompare
    ? firstCharacterOfUserFirstName.concat(middleNamesFromUserToCompare.slice(0, 1))
    : firstCharacterOfUserFirstName.concat(FILLER_CHARACTER)

  return otherNameCharactersFromLicense.toLocaleUpperCase() === charactersForComparison.toUpperCase()
}

/**
 * Validates a UK driving licence number against the users gender.
 * Returns true if the candidate's gender is correct based on their
 * DoB on their licenseand otherwise, false.
 *
 * (Will also return `null` if dateOfBirth or licenceNumber or gender is falsy)
 *
 * @param {string} licenceNumber
 * @param {string} dateOfBirth
 * @param {number} gender
 * @returns {boolean} true / false
 */
export const validateDrivingLicenceAgainstGender = (licenceNumber, dateOfBirth, gender) => {
  if (!dateOfBirth || !licenceNumber || !gender) return null

  const MALE = 1
  const FEMALE = 2

  // extracts the date of birth information from the driving licence number as a 6 digit string
  const dobInfo = licenceNumber.slice(5, 11)

  // The first digit of the month number is increased by 5 if the holder is female
  const genderIndicatorFromLicense = parseInt(dobInfo.slice(1, 3), 10)
  const genderToCompare = genderIndicatorFromLicense > 12 ? FEMALE : MALE

  return genderToCompare === gender
}
