import React from 'react'
import PropTypes from 'prop-types'
import { Base, Form, margin, padding, color, border, shape, scale } from 'chassis-react'

const FormCheckboxInput = props => {
  const { children, id, label, hint, error, name, util, urlHint, href, urlHintText, ...rest } = props

  return (
    <Form group error={!!error && true}>
      {!!error && <Form validation util={scale.fs2}>{error}</Form>}
      <Base
        util={[
          border.a1,
          color.bcGrey3,
          color.bgGrey2,
          margin.t1,
          margin.b1,
          padding.h1,
          padding.v1,
          padding.h2Sm,
          padding.v3Sm,
          shape.rounded
        ]}
      >
        {label && (
          <Form label htmlFor={id}>
            {label}
          </Form>
        )}

        {hint && <Form hint>{hint}</Form>}

        <Form checkbox id={name || id} util={util} name={name} {...rest}>
          {children}
        </Form>
        {urlHint && <Form hint util={[margin.l4, margin.t1, scale.fs3]}><a href={href} target='_blank' rel="noopener noreferrer">{urlHintText}</a></Form>}
      </Base>
    </Form>
  )
}

FormCheckboxInput.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  error: PropTypes.string,
  name: PropTypes.string,
  util: PropTypes.oneOfType([PropTypes.instanceOf(Array), PropTypes.string]),
  urlHint: PropTypes.bool,
  href: PropTypes.string,
  urlHintText: PropTypes.string
}

export default FormCheckboxInput
