import auth from '../auth'

const roles = {
  newStart: 0,
  profileEditor: 50,
  healthAndSafety: 51,
  medicalCondition: 52,
  medicalInsurance: 53,
  wages: 100,
  people: 200,
  recruitment: 400,
  superAdmin: 500,
  itDev: 999
}

const getCurrentRole = () => auth.getSessionValue('AccessRole')
const getAllRoles = () => auth.getSessionValue('AllRoles') || []

const allow = groups => {
  // convert to array if it isn't already
  const groupsToCheck = Array.isArray(groups) ? groups : [groups]

  const isInUsersGroup = group => roles[group] === getCurrentRole()
  return groupsToCheck.some(isInUsersGroup)
}

const isProfileEditor = () => getAllRoles().includes(roles.profileEditor)
const isMedicalConditionViewer = () => getAllRoles().includes(roles.medicalCondition)
const isMedicalInsuranceViewer = () => getAllRoles().includes(roles.medicalInsurance)
const isHealthAndSafetyUser = () => getCurrentRole() === roles.healthAndSafety

export default { allow, deny: groups => !allow(groups), isProfileEditor, isHealthAndSafetyUser, isMedicalConditionViewer, isMedicalInsuranceViewer }
