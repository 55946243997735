import * as yup from 'yup'
import moment from 'moment'
import '../../../../utilities/toUTC'

import {
  validateDrivingLicenceAgainstDob,
  isValidUkDrivingLicence,
  validateDrivingLicenseAgainstSurname,
  validateDrivingLicenseAgainstOtherNames,
  validateDrivingLicenceAgainstGender
} from '../../../../utilities/validateDrivingLicence'
import {
  checkGapsExistInHistory,
  checkRemainingYearsRequiredAddressHistory,
  totalYearsRequired
} from '../../../../utilities/validateAddressHistory'

export const PersonalDetailsSchema = yup.object().shape({
  GenderId: yup
    .number()
    .required('This field is required')
    .typeError('This field is required'),
  TitleId: yup
    .number()
    .required('This field is required')
    .typeError('This field is required'),
  Forename: yup.string().required('This field is required'),
  Surname: yup.string().required('This field is required'),
  MothersMaidenName: yup.string().required('This field is required'),
  KnownByOtherNames: yup
    .boolean()
    .required('This field is required')
    .typeError('This field is required'),
  OtherNames: yup.array().when('KnownByOtherNames', {
    is: true,
    then: yup.array().min(1, 'Please enter at least one other name'),
    otherwise: yup.array().min(0)
  })
})

export const OtherNameSchema = yup.object().shape({
  TitleId: yup
    .number()
    .required('This field is required')
    .typeError('This field is required'),
  Firstname: yup.string().required('This field is required'),
  Surname: yup.string().required('This field is required'),
  UsedFrom: yup
    .date()
    .toUTC()
    .required('This field is required')
    .typeError('Please check required date format'),
  UsedTo: yup
    .date()
    .toUTC()
    .required('This field is required')
    .typeError('Please check required date format')
})

export const BirthDetailsSchema = yup.object().shape({
  DateOfBirth: yup
    .date()
    .toUTC()
    .required('This field is required')
    .typeError('Please check required date format'),
  TownOfBirth: yup.string().required('This field is required'),
  CountryOfBirthId: yup
    .number()
    .positive()
    .required('This field is required')
    .typeError('This field is required'),
  NationalityId: yup
    .number()
    .positive()
    .required('This field is required')
    .typeError('This field is required')
})

export const generateAdditionalDetailsSchema = (dateOfBirth, surname, forename, middleNMames, gender) =>
  yup.object().shape({
    UkNationalInsuranceNumber: yup
      .string()
      .transform(value => value.toUpperCase().replace(/\s+/g, ''))
      .test({
        name: 'NIMatchesRegex',
        test: niNumber => /^[A-CEGHJ-PR-TW-Z]{1}[A-CEGHJ-NPR-TW-Z]{1}[0-9]{6}[A-DFM]{0,1}$/.test(niNumber),
        message: 'Please provide a valid NI number. For example, "QQ 12 34 56 C".'
      }),
    PassportNumber: yup.string().when('HasPassport', {
      is: true,
      then: yup
        .string()
        .required('This field is required')
        .typeError('This field is required')
        .max(11, 'Must be no more than 11 characters')
    }),
    PassportCountryOfIssueId: yup
      .string()
      .nullable()
      .when('HasPassport', {
        is: true,
        then: yup
          .string()
          .required('This field is required')
          .typeError('This field is required')
      }),
    DrivingLicenceCountryOfIssueId: yup.mixed().when('HasDrivingLicence', {
      is: true,
      then: yup
        .number()
        .required('This field is required')
        .positive('This field is required')
        .typeError('This field is required')
    }),
    DrivingLicenceNumber: yup.string().when('HasDrivingLicence', {
      is: true,
      then: yup
        .string()
        .required('This field is required')
        .max(50, 'This field must contain 50 characters or less')
        .transform(value => value.toUpperCase().replace(/\s+/g, ''))
        .test({
          name: 'TestDrivingLicenceIsValid',
          test(licenceNumber) {
            const isUk = parseInt(this.parent.DrivingLicenceCountryOfIssueId, 10) === 235
            const isNi = isUk && licenceNumber.length === 8

            if (isUk && !isNi) {
              return isValidUkDrivingLicence(licenceNumber)
            }

            // All other countries, including NI
            return true
          },
          message: 'Your driving licence number is not a valid format.'
        })
        .test({
          name: 'TestDrivingLicenceYearMatchesUserDob',
          test(licenceNumber) {
            const isUk = parseInt(this.parent.DrivingLicenceCountryOfIssueId, 10) === 235
            const isNi = isUk && licenceNumber.length === 8

            if (isUk && !isNi) {
              return validateDrivingLicenceAgainstDob(dateOfBirth, licenceNumber)
            }

            // All other countries, including NI
            return true
          },
          message:
            'Your driving licence number does not match the date of birth you entered in the Birth details tab.'
        })
        .test({
          name: 'TestDrivingLicenceMatchesSurname',
          test(licenceNumber) {
            const isUk = parseInt(this.parent.DrivingLicenceCountryOfIssueId, 10) === 235
            const isNi = isUk && licenceNumber.length === 8

            if (isUk && !isNi) {
              return validateDrivingLicenseAgainstSurname(licenceNumber, surname)
            }

            // All other countries, including NI
            return true
          },
          message:
            'Your driving licence number does not match the surname you entered in the Personal details tab.'
        })
        .test({
          name: 'TestDrivingLicenceMatchesFornameAndAnyMiddleNames',
          test(licenceNumber) {
            const isUk = parseInt(this.parent.DrivingLicenceCountryOfIssueId, 10) === 235
            const isNi = isUk && licenceNumber.length === 8

            if (isUk && !isNi) {
              return validateDrivingLicenseAgainstOtherNames(licenceNumber, forename, middleNMames)
            }

            // All other countries, including NI
            return true
          },
          message:
            'Your driving licence number does not match your forename and any middle names you entered in the Personal details tab.'
        })
        .test({
          name: 'TestDrivingLicenceMatchesGender',
          test(licenceNumber) {
            const isUk = parseInt(this.parent.DrivingLicenceCountryOfIssueId, 10) === 235
            const isNi = isUk && licenceNumber.length === 8

            if (isUk && !isNi) {
              return validateDrivingLicenceAgainstGender(licenceNumber, dateOfBirth, gender)
            }

            // All other countries, including NI
            return true
          },
          message:
            'Your driving licence number does not match the gender you selected in the Personal details tab.'
        })
    }),
    NationalIdentityCardCountryOfIssueId: yup
      .string()
      .nullable()
      .when('HasNationalIdentityCard', {
        is: true,
        then: yup
          .string()
          .required('This field is required')
          .typeError('This field is required')
      }),
    NationalIdentityCardNumber: yup.string().when('HasNationalIdentityCard', {
      is: true,
      then: yup
        .string()
        .required('This field is required')
        .test({
          name: 'IDCardMatchesRegex',
          test: value => /^[A-Za-z0-9]{1,20}$/.test(value),
          message: 'This field can only include letters and numbers'
        })
        .typeError('This field is required')
    })
  })

export const createDisclosureContactSchema = addressHistory => {
  const yearsRemaining = checkRemainingYearsRequiredAddressHistory(
    addressHistory,
    totalYearsRequired,
    moment().utc()
  )
  return yup.object().shape({
    DaytimePhoneNumber: yup
      .string()
      .required('This field is required')
      // matches a plus sign, followed by 1 - 3 digits. e.g., a dialling code, then one or more digits or spaces
      .matches(/^\+\d{1,3}/, {
        excludeEmptyString: true,
        message: 'Daytime telephone number must start with a country dialling code. e.g. +44'
      })
      .matches(/^\+?\d+$/, {
        excludeEmptyString: true,
        message: 'Daytime telephone number can only contain the "+" sign and digits'
      })
      .min(6, 'Daytime telephone number must have at least 6 characters')
      .max(13, 'Daytime telephone number must have no more than 13 characters'),
    EveningPhoneNumber: yup
      .string()
      .required('This field is required')
      // matches a plus sign, followed by 1 - 3 digits. e.g., a dialling code, then one or more digits or spaces
      .matches(/^\+\d{1,3}/, {
        excludeEmptyString: true,
        message: 'Evening telephone number must start with a country dialling code. e.g. +44'
      })
      .matches(/^\+?\d+$/, {
        excludeEmptyString: true,
        message: 'Evening telephone number can only contain the "+" sign and digits'
      })
      .min(6, 'Evening telephone number must have at least 6 characters')
      .max(13, 'Evening telephone number must have no more than 13 characters'),
    EmailAddress: yup
      .string()
      .required('This field is required')
      .email('This field must be a valid email address'),
    AddressHistory: yup
      .array()
      .test({
        name: 'no-gaps',
        test: () => !checkGapsExistInHistory(addressHistory, moment().utc()),
        message: 'There should be no gaps in your address history',
        exclusive: false
      })
      .test({
        name: 'at-least-five-years',
        test: () => yearsRemaining <= 0,
        params: { yearsRemaining },
        message: `Please provide ${yearsRemaining} more year${
          yearsRemaining > 1 ? 's' : ''
        } of address history`,
        exclusive: false
      })
  })
}

export const createAddressSchema = selectedAddress =>
  yup.object().shape({
    Postcode: yup
      .string()
      .required('This field is required')
      .max(10, 'This field must contain 10 characters or less'),
    Line1: yup
      .string()
      .required('This field is required')
      .max(100, 'This field must contain 100 characters or less'),
    Line2: yup.string().max(100, 'This field must contain 100 characters or less'),
    Town: yup
      .string()
      .required('This field is required')
      .max(100, 'This field must contain 100 characters or less'),
    County: yup.string().max(100, 'This field must contain 100 characters or less'),
    CountryId: yup
      .number()
      .required('This field is required')
      .typeError('This field is required'),
    DateFrom: yup
      .date()
      .toUTC()
      .required('This field is required')
      .test({
        name: 'before-date-from',
        test: dateFrom => moment.utc(dateFrom).isBefore(moment.utc(selectedAddress.DateTo)),
        message: 'Resident From should be before Resident To',
        exclusive: false
      })
      .typeError('Please check required date format')
  })
