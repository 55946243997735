import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import api from '../api'
import userService from '../services/userService'
import { UserProvider } from '../context/UserContext'
import valueFormat from '../utilities/valueFormat'
import auth from '../auth'
import { UserTypes } from '../config/UserTypes'

class UserContainer extends Component {
  constructor(props) {
    super(props)
    const { id } = props
    const sessionValues = auth.getAppData()

    this.state = {
      AuthToken: sessionValues.AuthToken,
      Id: id || sessionValues.Id,

      AddressList: [],
      Titles: [],
      Gender: [],
      CountryList: [],
      NationalityList: [],
      Firstname: '',
      Surname: '',
      TitleId: '',
      TitleName: '',
      DateOfBirth: '',
      NINumber: '',
      PassportExpiry: '',
      UserType: UserTypes.UNKNOWN,
      TelNumber: '',
      EmailAddress: '',
      DateCreated: '',
      JobTitleId: '',
      JobTitleName: '',
      BranchId: '',
      BranchName: '',
      DepartmentId: '',
      DepartmentName: '',
      ManagerName: '',
      StartDate: '',
      Flexitime: false,
      FlexiType: '',
      WorkingLocationCategoryId: '',
      WorkingLocationCategoryName: '',
      RegionId: '',
      RegionName: '',
      Steps: [],
      dependentList: [],

      Addresses: [
        {
          Line1: '',
          Line2: '',
          Town: '',
          Postcode: ''
        }
      ],
      ContactPreferences: [],
      Documents: [],
      DocumentItem: {},
      WageInformation: {
        Id: '',
        UserId: '',
        EmployeeStatementType: '',
        EmployeeStatementTypeId: '',
        StudentBeforeSixthApril: false,
        HasUnpaidStudentLoan: false,
        StudentLoanPaidDirectToStudentLoanCompany: false,
        StudentLoanTypeId: null,
        HasUnpaidPostgradLoan: false,
        PostgradStudentBeforeSixthApril: false,
        PostgradLoanPaidDirectToStudentLoanCompany: false,
        SortCode: null,
        AccountNumber: null,
        AccountHolder: ''
      },

      LifeAssurance: [],

      Medical: {
        NoDaysAbsentInLastTwelveMonths: '',
        HowManyPeriodsOfAbsence: '',
        DetailsOfEachAbsence: '',
        CirculatoryProblems: '',
        CirculatoryProblemsStartDate: '',
        CirculatoryProblemsEndDate: '',
        CirculatoryProblemsDetails: '',
        HeartProblems: '',
        HeartProblemsStartDate: '',
        HeartProblemsEndDate: '',
        HeartProblemsDetails: '',
        ChestProblems: '',
        ChestProblemsStartDate: '',
        ChestProblemsEndDate: '',
        ChestProblemsDetails: '',
        EpilepsyOrFainting: '',
        EpilepsyOrFaintingStartDate: '',
        EpilepsyOrFaintingEndDate: '',
        EpilepsyOrFaintingDetails: '',
        SkinDisorders: '',
        SkinDisordersStartDate: '',
        SkinDisordersEndDate: '',
        SkinDisordersDetails: '',
        OperationsOrFractures: '',
        OperationsOrFracturesStartDate: '',
        OperationsOrFracturesEndDate: '',
        OperationsOrFracturesDetails: '',
        AnyCurrentMedication: '',
        AnyCurrentMedicationStartDate: '',
        AnyCurrentMedicationDetails: '',
        BackArthritisRheumatism: '',
        BackArthritisRheumatismStartDate: '',
        BackArthritisRheumatismEndDate: '',
        BackArthritisRheumatismDetails: '',
        BonesJointsTendons: '',
        BonesJointsTendonsStartDate: '',
        BonesJointsTendonsEndDate: '',
        BonesJointsTendonsDetails: '',
        OtherSignificantHealthProblems: '',
        OtherSignificantHealthProblemsDetails: '',
        WorkedWithHighNoiseLevels: '',
        WorkedWithHighNoiseLevelsDetails: '',
        IndustrialInjuryClaim: '',
        IndustrialInjuryClaimDetails: '',
        AnyOtherConditions: '',
        ShareWithManagers: false,
        LoadedMedical: false
      },

      Disclosure: {
        Id: '',
        UserId: '',
        GenderId: '',
        GenderName: '',
        TitleId: '',
        TitleName: '',
        Forename: '',
        MiddleNames: '',
        Surname: '',
        KnownByOtherNames: null,
        OtherNames: [],
        DateOfBirth: '',
        TownOfBirth: '',
        CountryOfBirthId: null,
        UkNationalInsuranceNumber: '',
        HasPassport: '',
        PassportNumber: '',
        PassportCountryOfIssueId: null,
        HasDrivingLicence: '',
        DrivingLicenceNumber: '',
        DrivingLicenceCountryOfIssueId: null,
        DaytimePhoneNumber: '',
        EveningPhoneNumber: '',
        EmailAddress: '',
        AddressHistory: [],
        MothersMaidenName: '',
        HasNationalIdentityCard: false,
        NationalIdentityCardNumber: '',
        NationalIdentityCardCountryOfIssueId: null,
        NationalityId: null,
        NationalityName: '',
        DBSPrivacyPolicyConsent: false,
        DBSResultThirdPartyConsent: false,
        DBSArnoldClarkStoreConsent: false
      },
      Apprentice: {
        BootSize: '',
        OverallsSize: '',
        PoloShirtsSize: '',
        TravelRequired: '',
        TravelStation: '',
        AccommodationRequired: '',
        DietaryRequirements: ''
      },
      DisclosureApiValidationErrors: [],
      Loaded: false,
      // General
      handleUpdateStep: this.handleUpdateStep,
      // Details
      handleChangePersonalDetails: this.handleChangePersonalDetails,
      handleSubmitPersonalDetails: this.handleSubmitPersonalDetails,
      handleAddressSearch: this.handleAddressSearch,
      handleChangeAddressDetails: this.handleChangeAddressDetails,
      handleAddressSelect: this.handleAddressSelect,
      // Documents
      handleViewDocument: this.handleViewDocument,
      handleSubmitAcceptOffer: this.handleSubmitAcceptOffer,
      // Disclosure
      handleLoadDisclosure: this.handleLoadDisclosure,
      handleChangeDisclosure: this.handleChangeDisclosure,
      handleAddDisclosureOtherNames: this.handleAddDisclosureOtherNames,
      handleRemoveDisclosureOtherName: this.handleRemoveDisclosureOtherName,
      handleSubmitDisclosure: this.handleSubmitDisclosure,
      handleDisclosureCompleteStep: this.handleDisclosureCompleteStep,
      handleDisclosureSelectAddress: this.handleDisclosureSelectAddress,
      handleDisclosureAddToAddressHistory: this.handleDisclosureAddToAddressHistory,
      handleDisclosureRemoveFromAddressHistory: this.handleDisclosureRemoveFromAddressHistory,
      updateNiNumber: this.updateNiNumber,
      handleChangeKnownByOtherNames: this.handleChangeKnownByOtherNames,
      updateDrivingLicence: this.updateDrivingLicence,
      setDisclosureApiValidationErrors: this.setDisclosureApiValidationErrors,
      // Wages
      handleChangeWageInformation: this.handleChangeWageInformation,
      handleSubmitWageInformation: this.handleSubmitWageInformation,
      // Life assurance
      handleSubmitLifeAssurance: this.handleSubmitLifeAssurance,
      handleAddLifeAssuranceBeneficiary: this.handleAddLifeAssuranceBeneficiary,
      handleDeleteLifeAssurance: this.handleDeleteLifeAssurance,
      // Medical
      handleLoadMedical: this.handleLoadMedical,
      handleChangeMedical: this.handleChangeMedical,
      handleSubmitMedical: this.handleSubmitMedical,
      // Apprentice
      handleChangeApprentice: this.handleChangeApprentice,
      handleSubmitApprentice: this.handleSubmitApprentice,
      resetApprenticeDefaults: this.resetApprenticeDefaults,

      // AxaHealthcare
      handleAddDependents: this.handleAddDependents,
      handleAddNewDependent: this.handleAddNewDependent,
      handleDeleteDependent: this.handleDeleteDependent,
      handleGetDependents: this.handleGetDependents,
      handleSubmitJoinAxaHealthcareWithDependents: this.handleSubmitJoinAxaHealthcareWithDependents,
      handleSubmitJoinAxaHealthcareWithoutDependents: this.handleSubmitJoinAxaHealthcareWithoutDependents,
      handleSubmitRefuseTojoinAxaHealthcare: this.handleSubmitRefuseTojoinAxaHealthcare,
      handleUpdateJoinAxaHealthcare: this.handleUpdateJoinAxaHealthcare,
      setAxaHealthCareValidationErrors: this.setAxaHealthCareValidationErrors,
      isJoinedAxaHealthcare: '',
      AxaHealthCareValidationErrors: [],

      // HandArmVibration
      HandArmVibration: {
        Id: 0,
        EverUsedHandheldVibratingToolsInYourJob: null,
        UseOfPowerToolsVibratingEquipmentOutsideWork: null,
        HowOftenDoYouUseThisEquipment: null,
        UseOfPowerToolsVibratingEquipmentOutsideWorkDetails: null,
        NewStartHandArmVibrationExposures: [],
        DiagnosedWithRaynaudsSyndrome: null,
        DiagnosedWithRaynaudsSyndromeDetails: null,
        AnyTinglingAfterUsingVibratingEquipment: null,
        TinglingAnyOtherTime: null,
        OneOrMoreFingersNumbAfterUsingVibratingEquipment: null,
        FingersWhiteWhenExposedToCold: null,
        DifficultyGettingFingersWarm: null,
        FingersWhiteAtAnyOtherTime: null,
        AnyOtherProblemsWithMusclesOrJointsOfHandsArms: null,
        DifficultyPickingUpSmallItemsOrOpeningJars: null,
        EverHadNeckArmOrHandInjuryOrOperation: null,
        EverHadNeckArmOrHandInjuryOrOperationDetails: null,
        AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVessels: null,
        AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVesselsDetails: null,
        OnAnyLongTermMedication: null,
        OnAnyLongTermMedicationDetails: null,
        NighttimePainTinglingOrNumbness: null,
        FingersWhiteAtAnyOtherTimeDetails: null
      },
      HandArmVibrationErrors: [],
      handleChangeTools: this.handleChangeTools,
      handleChangeFingersWhiteWhenExposedToCold: this.handleChangeFingersWhiteWhenExposedToCold,
      handleAddNewStartHandArmVibrationExposures: this.handleAddNewStartHandArmVibrationExposures,
      handleDeleteOccupationalHistory: this.handleDeleteOccupationalHistory,
      handleUpdateHealthQuestion1: this.handleUpdateHealthQuestion1,
      handleUpdateHealthQuestion2: this.handleUpdateHealthQuestion2,
      handleSubmitNewStartHandArmVibration: this.handleSubmitNewStartHandArmVibration,
      submitNeverUsedHandArmVibration: this.submitNeverUsedHandArmVibration,
      handleUpdateHandArmVibration: this.handleUpdateHandArmVibration
    }
  }

  componentDidMount() {
    const { Id } = this.state

    api.title.getAll().then(res => this.setState({ Titles: res.data }))

    if (Id) {
      api.newStart.getById(Id).then(res => {
        this.setState({ ...res.data }, () =>
          api.newStartWageInformation.getByUserId(Id).then(wageInfo => {
            this.setState({ WageInformation: wageInfo.data }, () =>
              api.newStartDocument.getByUserId(Id).then(docs =>
                this.setState(
                  { Documents: (docs && docs.data) || null },
                  () =>
                    api.newStartLifeAssurance.getByUserId(Id).then(lifeAssurance => {
                      this.setState({ LifeAssurance: lifeAssurance.data.Beneficiaries }, () =>
                        api.newStartMedicalForm.getByUserId(Id).then(medical => {
                          const {
                            state: { UserType }
                          } = this
                          this.setState(
                            { Medical: medical.data, Loaded: UserType === UserTypes.NORMAL },
                            () => {
                              if (UserType === UserTypes.APPRENTICE) {
                                api.newStartApprentice
                                  .getByUserId(Id)
                                  .then(apprenticeResponse =>
                                    this.setState({ Apprentice: apprenticeResponse.data, Loaded: true })
                                  )
                                  .catch(() => {
                                    // No apprentice data yet
                                    this.setState({ Loaded: true })
                                  })
                              }
                            }
                          )
                        })
                      )
                    }),
                  api.newStartMedicalInsurance.getDependents(Id).then(dependents => {
                    const { data } = dependents
                    const { Dependents, OptOut } = data
                    this.setState({ dependentList: Dependents, isJoinedAxaHealthcare: !OptOut })
                  }),
                  api.newStartHandArmVibration.getNewStartHandArmVibration(Id).then(HAVs => {
                    const { data } = HAVs
                    if (data) {
                      this.setState({
                        HandArmVibration: data
                      })
                    }
                  })
                )
              )
            )
          })
        )
      })
    }
  }

  // General

  handleUpdateStep = userStepId => {
    const { Id } = this.state

    api.newStartStep
      .complete(userStepId)
      .then(() =>
        api.newStart.getById(Id).then(res =>
          this.setState({ Steps: res.data.Steps }, () => {
            const {
              state: { Steps }
            } = this
            const location = Steps.every(step => step.Completed) ? '/thank-you' : '/'
            navigate(location)
          })
        )
      )
      .catch(() => navigate('/'))
  }

  unlockAccount = () => {
    this.setState({ IsAccountLocked: false })
  }

  // Details

  handleChangePersonalDetails = e => {
    const { name, value, dataset } = e.target
    const { format } = dataset

    this.setState({ [name]: valueFormat(format, value) })
  }

  handleChangeAddressDetails = e => {
    const { Addresses } = this.state
    const { name, value } = e.target

    this.setState({
      Addresses: [
        {
          ...Addresses[0],
          [name]: value
        }
      ]
    })
  }

  handleAddressSearch = (e, postcode) => {
    e.preventDefault()

    api.addressLookup(postcode).then(res => this.setState({ AddressList: res.data }))
  }

  handleAddressSelect = e => {
    const { Addresses, AddressList } = this.state

    this.setState({
      Addresses: [
        {
          ...Addresses[0],
          ...AddressList[e.target.value]
        }
      ],
      AddressList: []
    })
  }

  handleSubmitPersonalDetails = () => {
    const { state } = this
    userService.updatePersonalDetails(this.state).then(res => {
      this.setState({
        ...state,
        ...res.data
      })
      setTimeout(() => {
        navigate('/details')
      }, 500)
    })
  }

  // Documents

  handleViewDocument = documentObj => {
    const { DocumentItem } = this.state

    this.setState({ DocumentItem: Object.assign(DocumentItem, ...documentObj) }, () => {
      api.newStartDocument.getByDocId(DocumentItem.Id).then(res => {
        this.setState({
          DocumentItem: {
            ...DocumentItem,
            ...res.data
          }
        })
      })
    })
  }

  // Disclosure

  handleLoadDisclosure = () => {
    const { Id, Disclosure } = this.state

    api.country.getAll().then(({ data }) => {
      this.setState({ CountryList: data })
    })

    api.nationality.getAll().then(res => this.setState({ NationalityList: res.data }))

    userService.loadDisclosureAndShapeState(Id).then(disclosure => {
      this.setState({
        Disclosure: { ...Disclosure, ...disclosure, UserId: parseInt(Id, 10) },
        Loaded: true
      })
    })
  }

  handleChangeDisclosure = e => {
    const { Disclosure } = this.state
    const { name, value, dataset } = e.target
    const { format } = dataset

    if (format === 'int') {
      this.setState({
        Disclosure: { ...Disclosure, [name]: parseInt(value, 10) }
      })
    } else if (format === 'bool') {
      this.setState({
        Disclosure: { ...Disclosure, [name]: JSON.parse(value) }
      })
    } else {
      this.setState({
        Disclosure: { ...Disclosure, [name]: value }
      })
    }
  }

  handleChangeApprentice = e => {
    const { Apprentice } = this.state
    const { name, value, dataset } = e.target

    const newValue = dataset.format === 'bool' ? JSON.parse(value) : value

    this.setState({ Apprentice: { ...Apprentice, [name]: newValue } }, this.resetApprenticeDefaults)
  }

  resetApprenticeDefaults = () => {
    const { Apprentice } = this.state
    const resetValues = {}

    if (!Apprentice.TravelRequired) {
      resetValues.TravelStation = ''
    }

    this.setState({ Apprentice: { ...Apprentice, ...resetValues } })
  }

  handleSubmitApprentice = () => {
    const { state } = this
    const { Id, Steps } = state
    return userService.addApprenticeForm(state).then(apprenticeFormResponse => {
      const apprenticeStepId = 8
      const stepId = Steps.find(s => s.StepId === apprenticeStepId).Id

      return api.newStartStep.complete(stepId).then(() => {
        api.newStart.getById(Id).then(newStartResponse => {
          this.setState({
            Apprentice: apprenticeFormResponse.data,
            Steps: newStartResponse.data.Steps
          })
        })
      })
    })
  }

  handleChangeKnownByOtherNames = e => {
    const KnownByOtherNames = JSON.parse(e.target.value)
    const { Disclosure } = this.state

    let newDisclosure = { ...Disclosure, KnownByOtherNames }

    if (!KnownByOtherNames) {
      newDisclosure = { ...newDisclosure, OtherNames: [] }
    }

    this.setState({ Disclosure: newDisclosure })
  }

  updateNiNumber = UkNationalInsuranceNumber => {
    const { Disclosure } = this.state
    this.setState({ Disclosure: { ...Disclosure, UkNationalInsuranceNumber } })
  }

  updateDrivingLicence = DrivingLicenceNumber => {
    const { Disclosure } = this.state
    this.setState({ Disclosure: { ...Disclosure, DrivingLicenceNumber } })
  }

  handleAddDisclosureOtherNames = e => {
    const { Disclosure } = this.state
    const { OtherNames } = Disclosure

    if (OtherNames !== null) {
      this.setState({
        Disclosure: { ...Disclosure, OtherNames: [...OtherNames, e] }
      })
    } else {
      this.setState({ Disclosure: { ...Disclosure, OtherNames: [e] } })
    }
  }

  handleRemoveDisclosureOtherName = index => {
    const { Disclosure } = this.state
    const { OtherNames } = Disclosure
    const otherNamesArray = OtherNames
    otherNamesArray.splice(index, 1)
    this.setState({
      Disclosure: { ...Disclosure, OtherNames: otherNamesArray }
    })
  }

  handleSubmitDisclosure = () => {
    const { Disclosure } = this.state
    return userService
      .updateDisclosure(Disclosure)
      .then(res => {
        this.setState({ Disclosure: res.data })
        return res.status
      })
      .catch(error => {
        const { response = [] } = error
        const { data = [] } = response
        const { Message = '' } = data
        if (Message) {
          this.setDisclosureApiValidationErrors(Message)
        }
        if (!Message) {
          this.setDisclosureApiValidationErrors(error.response.data)
        }
      })
  }

  handleDisclosureCompleteStep = userStepId => {
    const { Id } = this.state
    api.newStartStep
      .complete(userStepId)
      .then(result => {
        if (result.status === 200) {
          api.newStart
            .getById(Id)
            .then(res =>
              this.setState({ Steps: res.data.Steps }, () => {
                const {
                  state: { Steps }
                } = this
                const location = Steps.every(step => step.Completed) ? '/thank-you' : '/'
                navigate(location)
              })
            )
            .catch(() => navigate('/'))
        }
      })
      .catch(error => {
        const { response = [] } = error
        const { data = [] } = response
        const { Message = '' } = data
        if (Message) {
          this.setDisclosureApiValidationErrors(Message)
        }
        if (!Message) {
          this.setDisclosureApiValidationErrors(error.response.data.title)
        }
      })
  }

  setDisclosureApiValidationErrors = error => {
    const { DisclosureApiValidationErrors } = this.state
    this.setState({
      DisclosureApiValidationErrors: [...DisclosureApiValidationErrors, { message: error }]
    })
    setTimeout(() => {
      this.setState({
        DisclosureApiValidationErrors: []
      })
    }, 7000)
    window.scrollTo(0, 0)
  }

  handleDisclosureAddToAddressHistory = addressObj => {
    const { Disclosure } = this.state
    const { AddressHistory } = Disclosure

    this.setState({
      Disclosure: {
        ...Disclosure,
        AddressHistory: [...AddressHistory, addressObj]
      }
    })
  }

  handleDisclosureRemoveFromAddressHistory = index => {
    const { Disclosure } = this.state
    const { AddressHistory } = Disclosure
    const addressHistoryArray = AddressHistory
    addressHistoryArray.splice(index, AddressHistory.length - index)
    this.setState({
      Disclosure: { ...Disclosure, AddressHistory: addressHistoryArray }
    })
  }

  handleDisclosureSelectAddress = () => {
    this.setState({ AddressList: [] })
  }

  // Wages

  handleLoadWageInformation = () => {
    const { Id } = this.state
    api.newStartWageInformation.getByUserId(Id).then(res => this.setState({ WageInformation: res.data }))
  }

  handleChangeWageInformation = e => {
    const { name, value, dataset } = e.target
    const { format } = dataset
    const { WageInformation } = this.state

    this.setState(
      {
        WageInformation: {
          ...WageInformation,
          [name]: valueFormat(format, value)
        }
      },
      this.rewriteWageInformationFields
    )
  }

  rewriteWageInformationFields = () => {
    const { WageInformation } = this.state

    if (!WageInformation.StudentBeforeSixthApril || !WageInformation.WasUndergraduate) {
      WageInformation.HasUnpaidStudentLoan = false
      WageInformation.StudentLoanPaidDirectToStudentLoanCompany = false
      WageInformation.StudentLoanTypeId = null
      WageInformation.WasUndergraduate = false
    }

    if (!WageInformation.StudentBeforeSixthApril || !WageInformation.WasPostgraduate) {
      WageInformation.HasUnpaidPostgradLoan = false
      WageInformation.PostgradStudentBeforeSixthApril = false
      WageInformation.PostgradLoanPaidDirectToStudentLoanCompany = false
      WageInformation.WasPostgraduate = false
    }

    if (!WageInformation.HasUnpaidStudentLoan) {
      WageInformation.StudentLoanPaidDirectToStudentLoanCompany = false
      WageInformation.StudentLoanTypeId = null
    }

    if (!WageInformation.HasUnpaidPostgradLoan) {
      WageInformation.PostgradStudentBeforeSixthApril = false
    }

    if (!WageInformation.PostgradStudentBeforeSixthApril) {
      WageInformation.PostgradLoanPaidDirectToStudentLoanCompany = false
    }

    this.setState({
      WageInformation: {
        ...WageInformation
      }
    })
  }

  handleSubmitWageInformation = () => {
    userService.updateWageInformation(this.state).then(() => this.handleLoadWageInformation())
  }

  // Life assurance

  handleAddLifeAssuranceBeneficiary = (e, Name, Relationship, Percentage) => {
    const { LifeAssurance } = this.state

    e.preventDefault()

    this.setState({
      LifeAssurance: [
        ...LifeAssurance,
        {
          Name,
          Relationship,
          Percentage
        }
      ]
    })
  }

  handleSubmitLifeAssurance = e => {
    const { LifeAssurance, Id } = this.state

    e.preventDefault()

    userService.updateLifeAssurance(LifeAssurance, Id).then(res => {
      this.setState({ LifeAssurance: res.data.Beneficiaries }, () => navigate('/life-assurance/confirm'))
    })
  }

  handleDeleteLifeAssurance = (e, indexToDelete) => {
    const { LifeAssurance } = this.state
    let newLifeAssurance

    e.preventDefault()

    if (LifeAssurance[indexToDelete].Id) {
      const itemId = LifeAssurance[indexToDelete].Id

      newLifeAssurance = LifeAssurance.filter((item, i) => i !== indexToDelete)
      this.setState({ LifeAssurance: newLifeAssurance }, () =>
        api.newStartLifeAssurance.deleteBeneficiary(itemId)
      )
    } else {
      newLifeAssurance = LifeAssurance.filter((item, i) => i !== indexToDelete)
      this.setState({ LifeAssurance: newLifeAssurance })
    }
  }

  // Medical

  handleLoadMedical = () => {
    const { Id, Medical } = this.state
    const { LoadedMedical } = Medical

    if (!LoadedMedical) {
      this.setState({ Loaded: false }, () =>
        api.newStartMedicalForm.getByUserId(Id).then(medical =>
          this.setState({
            Medical: {
              ...medical.data,
              LoadedMedical: true
            },
            Loaded: true
          })
        )
      )
    }
  }

  handleChangeMedical = e => {
    const { Medical } = this.state
    const { name, value, dataset } = e.target
    const { format } = dataset

    this.setState({
      Medical: {
        ...Medical,
        [name]: valueFormat(format, value)
      }
    })
  }

  handleUpdateHandArmVibration = res => {
    const { HandArmVibration } = this.state

    this.setState({
      HandArmVibration: {
        ...HandArmVibration,
        Id: res.data.Id
      }
    })
  }

  handleSubmitMedical = () => {
    const { Medical, Id } = this.state

    userService.updateMedicalDetails(Medical, Id).then(res => {
      this.setState({ Medical: { ...res.data } })
    })
  }

  handleSubmitRefuseTojoinAxaHealthcare = () => {
    const { Id, Steps } = this.state
    userService.refuseTojoinAxaPrivateHealthCare(Id).then(res => {
      if (res.status === 200) {
        setTimeout(() => {
          const userTaskAxaForm = Steps.find(step => step.Step.Id === 9)
          this.setState({ dependentList: [] })
          this.handleUpdateStep(userTaskAxaForm.Id)
        }, 500)
      }
    })
  }

  handleSubmitJoinAxaHealthcareWithoutDependents = () => {
    const { Id, Steps } = this.state
    userService.submitJoinAxaHealthCareWithoutDependents(Id).then(res => {
      if (res.status === 200) {
        setTimeout(() => {
          this.setState({ dependentList: [] })
          const userTaskAxaForm = Steps.find(step => step.Step.Id === 9)
          this.handleUpdateStep(userTaskAxaForm.Id)
        }, 500)
      }
    })
  }

  handleSubmitJoinAxaHealthcareWithDependents = () => {
    const { Id, dependentList } = this.state
    userService
      .submitJoinAxaHealthCareWithDependents({ Id, dependentList })
      .then(res => {
        if (res.status === 200) {
          setTimeout(() => {
            navigate('/axa-healthcare/confirm')
          }, 500)
        }
      })
      .catch(error => {
        const { response = [] } = error
        const { data = [] } = response
        const { Message = '' } = data
        if (Message) {
          this.setAxaHealthCareValidationErrors(Message)
        }
      })
  }

  handleGetDependents = () => {
    const { Id } = this.state
    userService.getDependents(Id).then(res => res)
  }

  handleAddDependents = dependents => {
    const { state } = this
    this.setState({
      ...state,
      dependentList: [...dependents]
    })
  }

  handleAddNewDependent = newPerson => {
    const { state } = this
    const { dependentList } = state
    this.setState({
      ...state,
      dependentList: [...dependentList, newPerson]
    })
  }

  handleDeleteDependent = i => {
    const { state } = this
    const { dependentList } = state
    const newSuposeList = dependentList
    newSuposeList.splice(i, 1)
    this.handleAddDependents([...newSuposeList])
  }

  handleUpdateJoinAxaHealthcare = value => {
    this.setState({ isJoinedAxaHealthcare: value })
  }

  setAxaHealthCareValidationErrors = error => {
    const { AxaHealthCareValidationErrors } = this.state
    this.setState({
      AxaHealthCareValidationErrors: [...AxaHealthCareValidationErrors, { message: error }]
    })
    setTimeout(() => {
      this.setState({
        AxaHealthCareValidationErrors: []
      })
    }, 7000)
    window.scrollTo(0, 0)
  }

  handleChangeTools = e => {
    const { HandArmVibration } = this.state
    const { value, name } = e.target
    if (value === 'true' || value === 'false') {
      this.setState({
        HandArmVibration: {
          ...HandArmVibration,
          [name]: value === 'true'
        }
      })
    } else {
      this.setState({
        HandArmVibration: {
          ...HandArmVibration,
          [name]: value
        }
      })
    }
  }

  handleChangeFingersWhiteWhenExposedToCold = e => {
    const { value, name } = e.target
    const { HandArmVibration } = this.state
    if (value === 'false') {
      this.setState({
        HandArmVibration: {
          ...HandArmVibration,
          [name]: false,
          DifficultyGettingFingersWarm: null,
          FingersWhiteAtAnyOtherTime: null
        }
      })
    } else {
      this.handleChangeTools(e)
    }
  }

  handleAddNewStartHandArmVibrationExposures = occupationalHistory => {
    const { HandArmVibration } = this.state
    const { NewStartHandArmVibrationExposures } = HandArmVibration
    this.setState({
      HandArmVibration: {
        ...HandArmVibration,
        NewStartHandArmVibrationExposures: [...NewStartHandArmVibrationExposures, occupationalHistory]
      }
    })
  }

  handleDeleteOccupationalHistory = i => {
    const { state } = this
    const { HandArmVibration } = state

    const { NewStartHandArmVibrationExposures } = HandArmVibration
    const formatNewStartHandArmVibrationExposures = NewStartHandArmVibrationExposures
    formatNewStartHandArmVibrationExposures.splice(i, 1)
    this.handleUpdateOccupationalHistory([...formatNewStartHandArmVibrationExposures])
  }

  handleUpdateOccupationalHistory = list => {
    const { state } = this
    const { HandArmVibration } = state
    this.setState({
      HandArmVibration: {
        ...HandArmVibration,
        NewStartHandArmVibrationExposures: [...list]
      }
    })
  }

  handleUpdateHealthQuestion1 = ({
    DiagnosedWithRaynaudsSyndrome,
    DiagnosedWithRaynaudsSyndromeDetails,
    AnyTinglingAfterUsingVibratingEquipment,
    TinglingAnyOtherTime,
    OneOrMoreFingersNumbAfterUsingVibratingEquipment,
    FingersWhiteWhenExposedToCold,
    DifficultyGettingFingersWarm,
    FingersWhiteAtAnyOtherTime,
    NighttimePainTinglingOrNumbness
  }) => {
    const { HandArmVibration } = this.state

    this.setState({
      HandArmVibration: {
        ...HandArmVibration,
        DiagnosedWithRaynaudsSyndrome,
        DiagnosedWithRaynaudsSyndromeDetails,
        AnyTinglingAfterUsingVibratingEquipment,
        TinglingAnyOtherTime,
        OneOrMoreFingersNumbAfterUsingVibratingEquipment,
        FingersWhiteWhenExposedToCold,
        DifficultyGettingFingersWarm,
        FingersWhiteAtAnyOtherTime,
        NighttimePainTinglingOrNumbness
      }
    })
  }

  handleUpdateHealthQuestion2 = ({
    DifficultyPickingUpSmallItemsOrOpeningJars,
    EverHadNeckArmOrHandInjuryOrOperation,
    AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVessels,
    OnAnyLongTermMedication,
    EverHadNeckArmOrHandInjuryOrOperationDetails,
    AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVesselsDetails,
    OnAnyLongTermMedicationDetails,
    AnyOtherProblemsWithMusclesOrJointsOfHandsArms
  }) => {
    const { HandArmVibration } = this.state
    this.setState({
      HandArmVibration: {
        ...HandArmVibration,
        DifficultyPickingUpSmallItemsOrOpeningJars,
        EverHadNeckArmOrHandInjuryOrOperation,
        AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVessels,
        OnAnyLongTermMedication,
        EverHadNeckArmOrHandInjuryOrOperationDetails,
        AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVesselsDetails,
        OnAnyLongTermMedicationDetails,
        AnyOtherProblemsWithMusclesOrJointsOfHandsArms
      }
    })
  }

  submitNeverUsedHandArmVibration = () => {
    const { Id, Steps, HandArmVibration } = this.state
    const {
      EverUsedHandheldVibratingToolsInYourJob,
      UseOfPowerToolsVibratingEquipmentOutsideWork,
      HowOftenDoYouUseThisEquipment
    } = HandArmVibration
    userService
      .submitNeverUsedHandArmVibration({
        entryId: HandArmVibration.Id,
        Id,
        EverUsedHandheldVibratingToolsInYourJob,
        UseOfPowerToolsVibratingEquipmentOutsideWork,
        HowOftenDoYouUseThisEquipment
      })
      .then(res => {
        if (res.status === 200) {
          setTimeout(() => {
            const userTaskHAVForm = Steps.find(step => step.Step.Id === 10)
            this.handleUpdateStep(userTaskHAVForm.Id)
            this.handleUpdateHandArmVibration(res)
          }, 500)
        }
      })
  }

  handleSubmitNewStartHandArmVibration = () => {
    const { Id, Steps, HandArmVibration } = this.state
    const {
      NewStartHandArmVibrationExposures,
      UseOfPowerToolsVibratingEquipmentOutsideWork,
      UseOfPowerToolsVibratingEquipmentOutsideWorkDetails,
      EverUsedHandheldVibratingToolsInYourJob,
      DiagnosedWithRaynaudsSyndrome,
      DiagnosedWithRaynaudsSyndromeDetails,
      AnyTinglingAfterUsingVibratingEquipment,
      TinglingAnyOtherTime,
      OneOrMoreFingersNumbAfterUsingVibratingEquipment,
      FingersWhiteWhenExposedToCold,
      DifficultyGettingFingersWarm,
      FingersWhiteAtAnyOtherTime,
      AnyOtherProblemsWithMusclesOrJointsOfHandsArms,
      DifficultyPickingUpSmallItemsOrOpeningJars,
      EverHadNeckArmOrHandInjuryOrOperation,
      AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVessels,
      OnAnyLongTermMedication,
      EverHadNeckArmOrHandInjuryOrOperationDetails,
      AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVesselsDetails,
      OnAnyLongTermMedicationDetails,
      NighttimePainTinglingOrNumbness,
      HowOftenDoYouUseThisEquipment,
      FingersWhiteAtAnyOtherTimeDetails
    } = HandArmVibration
    userService
      .submitAddNewStartHandArmVibration({
        entryId: HandArmVibration.Id,
        Id,
        NewStartHandArmVibrationExposures,
        EverUsedHandheldVibratingToolsInYourJob,
        UseOfPowerToolsVibratingEquipmentOutsideWork,
        UseOfPowerToolsVibratingEquipmentOutsideWorkDetails,
        DiagnosedWithRaynaudsSyndrome,
        DiagnosedWithRaynaudsSyndromeDetails,
        AnyTinglingAfterUsingVibratingEquipment,
        TinglingAnyOtherTime,
        OneOrMoreFingersNumbAfterUsingVibratingEquipment,
        FingersWhiteWhenExposedToCold,
        DifficultyGettingFingersWarm,
        FingersWhiteAtAnyOtherTime,
        AnyOtherProblemsWithMusclesOrJointsOfHandsArms,
        DifficultyPickingUpSmallItemsOrOpeningJars,
        EverHadNeckArmOrHandInjuryOrOperation,
        AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVessels,
        OnAnyLongTermMedication,
        EverHadNeckArmOrHandInjuryOrOperationDetails,
        AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVesselsDetails,
        OnAnyLongTermMedicationDetails,
        NighttimePainTinglingOrNumbness,
        HowOftenDoYouUseThisEquipment,
        FingersWhiteAtAnyOtherTimeDetails
      })
      .then(res => {
        if (res.status === 200) {
          setTimeout(() => {
            const userTaskAxaForm = Steps.find(step => step.Step.Id === 10)
            this.handleUpdateStep(userTaskAxaForm.Id)
          }, 500)
        }
      })
      .catch(error => {
        const { response = [] } = error
        const { data = [] } = response
        const { Message = '' } = data
        if (Message) {
          const { HandArmVibrationErrors } = this.state
          this.setState({
            HandArmVibrationErrors: [...HandArmVibrationErrors, { message: Message }]
          })
          setTimeout(() => {
            this.setState({
              HandArmVibrationErrors: []
            })
          }, 9000)
          window.scrollTo(0, 0)
        }
      })
  }

  render() {
    const { children } = this.props
    return <UserProvider value={this.state}>{children}</UserProvider>
  }
}

UserContainer.propTypes = {
  id: PropTypes.string,
  children: PropTypes.shape({})
}
export default UserContainer
