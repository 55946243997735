import * as yup from 'yup'

const requiredMessage = 'This field is required'

export const MedicalSchema = yup.object().shape({
  CirculatoryProblems: yup
    .boolean()
    .required(requiredMessage)
    .typeError(requiredMessage),
  CirculatoryProblemsStartDate: yup.string().when('CirculatoryProblems', {
    is: true,
    then: yup
      .string()
      .required('This field is required')
      .nullable(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  CirculatoryProblemsEndDate: yup
    .string()
    .nullable()
    .notRequired(),
  CirculatoryProblemsDetails: yup.string().when('CirculatoryProblems', {
    is: true,
    then: yup
      .string()
      .required('This field is required')
      .nullable(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  HeartProblems: yup
    .boolean()
    .required(requiredMessage)
    .typeError(requiredMessage),
  HeartProblemsStartDate: yup.string().when('HeartProblems', {
    is: true,
    then: yup
      .string()
      .required('This field is required')
      .nullable(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  HeartProblemsEndDate: yup
    .string()
    .nullable()
    .notRequired(),
  HeartProblemsDetails: yup.string().when('HeartProblems', {
    is: true,
    then: yup
      .string()
      .required('This field is required')
      .nullable(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  ChestProblems: yup
    .boolean()
    .required(requiredMessage)
    .typeError(requiredMessage),
  ChestProblemsStartDate: yup.string().when('ChestProblems', {
    is: true,
    then: yup
      .string()
      .required('This field is required')
      .nullable(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  ChestProblemsEndDate: yup
    .string()
    .nullable()
    .notRequired(),
  ChestProblemsDetails: yup.string().when('ChestProblems', {
    is: true,
    then: yup
      .string()
      .required('This field is required')
      .nullable(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  EpilepsyOrFainting: yup
    .boolean()
    .required(requiredMessage)
    .typeError(requiredMessage),
  EpilepsyOrFaintingStartDate: yup.string().when('EpilepsyOrFainting', {
    is: true,
    then: yup
      .string()
      .required('This field is required')
      .nullable(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  EpilepsyOrFaintingEndDate: yup
    .string()
    .nullable()
    .notRequired(),
  EpilepsyOrFaintingDetails: yup.string().when('EpilepsyOrFainting', {
    is: true,
    then: yup
      .string()
      .required('This field is required')
      .nullable(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  SkinDisorders: yup
    .boolean()
    .required(requiredMessage)
    .typeError(requiredMessage),
  SkinDisordersStartDate: yup.string().when('SkinDisorders', {
    is: true,
    then: yup
      .string()
      .required('This field is required')
      .nullable(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  SkinDisordersEndDate: yup
    .string()
    .nullable()
    .notRequired(),
  SkinDisordersDetails: yup.string().when('SkinDisorders', {
    is: true,
    then: yup
      .string()
      .required('This field is required')
      .nullable(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  OperationsOrFractures: yup
    .boolean()
    .required(requiredMessage)
    .typeError(requiredMessage),
  OperationsOrFracturesStartDate: yup.string().when('OperationsOrFractures', {
    is: true,
    then: yup
      .string()
      .required('This field is required')
      .nullable(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  OperationsOrFracturesEndDate: yup
    .string()
    .nullable()
    .notRequired(),
  OperationsOrFracturesDetails: yup.string().when('OperationsOrFractures', {
    is: true,
    then: yup
      .string()
      .required('This field is required')
      .nullable(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  AnyCurrentMedication: yup
    .boolean()
    .required(requiredMessage)
    .typeError(requiredMessage),
  AnyCurrentMedicationStartDate: yup.string().when('AnyCurrentMedication', {
    is: true,
    then: yup
      .string()
      .required('This field is required')
      .nullable(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  AnyCurrentMedicationDetails: yup.string().when('AnyCurrentMedication', {
    is: true,
    then: yup
      .string()
      .required('This field is required')
      .nullable(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  BackArthritisRheumatism: yup
    .boolean()
    .required(requiredMessage)
    .typeError(requiredMessage),
  BackArthritisRheumatismStartDate: yup.string().when('BackArthritisRheumatism', {
    is: true,
    then: yup
      .string()
      .required('This field is required')
      .nullable(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  BackArthritisRheumatismEndDate: yup
    .string()
    .nullable()
    .notRequired(),
  BackArthritisRheumatismDetails: yup.string().when('BackArthritisRheumatism', {
    is: true,
    then: yup
      .string()
      .required('This field is required')
      .nullable(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  BonesJointsTendons: yup
    .boolean()
    .required(requiredMessage)
    .typeError(requiredMessage),
  BonesJointsTendonsStartDate: yup.string().when('BonesJointsTendons', {
    is: true,
    then: yup
      .string()
      .required('This field is required')
      .nullable(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  BonesJointsTendonsEndDate: yup
    .string()
    .nullable()
    .notRequired(),
  BonesJointsTendonsDetails: yup.string().when('BonesJointsTendons', {
    is: true,
    then: yup
      .string()
      .required('This field is required')
      .nullable(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  OtherSignificantHealthProblems: yup
    .boolean()
    .required(requiredMessage)
    .typeError(requiredMessage),
  OtherSignificantHealthProblemsDetails: yup.string().when('OtherSignificantHealthProblems', {
    is: true,
    then: yup
      .string()
      .required('This field is required')
      .nullable(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  WorkedWithHighNoiseLevels: yup
    .boolean()
    .required(requiredMessage)
    .typeError(requiredMessage),
  WorkedWithHighNoiseLevelsDetails: yup.string().when('WorkedWithHighNoiseLevels', {
    is: true,
    then: yup
      .string()
      .required('This field is required')
      .nullable(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  }),
  IndustrialInjuryClaim: yup
    .boolean()
    .required(requiredMessage)
    .typeError(requiredMessage),
  IndustrialInjuryClaimDetails: yup.string().when('IndustrialInjuryClaim', {
    is: true,
    then: yup
      .string()
      .required('This field is required')
      .nullable(),
    otherwise: yup
      .string()
      .nullable()
      .notRequired()
  })
})
