import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import { Base, Button, Text, border, color, padding, position, margin, scale, shape, Alert } from 'chassis-react'

import api from '../../../api'

import accessRole from '../../../services/accessRole'

import stepperSteps from '../../../config/stepperSteps'

import ContentLoader from '../../../components/ContentLoader'
import ContentWrap from '../../../components/ContentWrap'
import DetailsList from '../../../components/DetailsList'
import Layout from '../../../components/Layout'
import Stepper from '../../../components/Stepper'
import TabList from '../../../components/TabList'

// overview page data lists
import overviewDetailsAddress from './Overview/overviewDetailsAddress'
import overviewDetailsContactInfo from './Overview/overviewDetailsContactInfo'
import overviewDetailsContractDocuments from './Overview/overviewDetailsContractDocuments'
import overviewDetailsPersonal from './Overview/overviewDetailsPersonal'
import overviewDetailsPosition from './Overview/overviewDetailsPosition'
import overviewDisclosureAdditionalDetails from './Overview/overviewDisclosureAdditionalDetails'
import overviewDisclosureBirthDetails from './Overview/overviewDisclosureBirthDetails'
import overviewDisclosureContactInformation from './Overview/overviewDisclosureContactInformation'
import overviewDisclosureDetails from './Overview/overviewDisclosureDetails'
import overviewDocuments from './Overview/overviewDocuments'
import overviewWageInformation from './Overview/overviewWageInformation'
import overviewLifeAssurance from './Overview/overviewLifeAssurance'
import overviewMedical from './Overview/overviewMedical'
import overviewApprentice from './Overview/overviewApprentice'
import overviewDisclosureAdminDetails from './Overview/overviewDisclosureAdminDetails'
import overviewDisclosureConsentDetails from './Overview/overviewDisclosureConsentDetails'

const healthSurveillanceDetail = healthSurveillance => [
  {
    dt: 'Have you ever used hand-held vibrating tools, machines or hand-fed processes in your job?',
    dd: healthSurveillance.EverUsedHandheldVibratingToolsInYourJob ? 'Yes' : 'No'
  },
  {
    dt: 'Do you use and power tools or other vibrating equipment should move',
    dd: healthSurveillance.UseOfPowerToolsVibratingEquipmentOutsideWork ? 'Yes' : 'No'
  },
  healthSurveillance.UseOfPowerToolsVibratingEquipmentOutsideWork &&
    healthSurveillance.HowOftenDoYouUseThisEquipment && {
      dt: 'How often do you use this equipment?',
      dd: healthSurveillance.HowOftenDoYouUseThisEquipment
    }
]

const healthQuestions1 = healthSurveillance => [
  healthSurveillance.DiagnosedWithRaynaudsSyndrome !== null && {
    dt: 'Have you been diagnosed with Raynaud’s syndrome (primary or secondary)?',
    dd: healthSurveillance.DiagnosedWithRaynaudsSyndrome ? 'Yes' : 'No'
  },
  healthSurveillance.DiagnosedWithRaynaudsSyndromeDetails !== null && {
    dt: 'Please give details of this?',
    dd: healthSurveillance.DiagnosedWithRaynaudsSyndromeDetails
  },
  healthSurveillance.AnyTinglingAfterUsingVibratingEquipment !== null && {
    dt:
      'Do you have any tingling of the fingers lasting more than 20 minutes after using vibrating equipment?',
    dd: healthSurveillance.AnyTinglingAfterUsingVibratingEquipment ? 'Yes' : 'No'
  },
  healthSurveillance.TinglingAnyOtherTime !== null && {
    dt: 'Do you have tingling of the fingers at any other time?',
    dd: healthSurveillance.TinglingAnyOtherTime ? 'Yes' : 'No'
  },
  healthSurveillance.OneOrMoreFingersNumbAfterUsingVibratingEquipment !== null && {
    dt: 'Do one or more of your fingers go numb more than 20 minutes after using vibrating equipment?',
    dd: healthSurveillance.OneOrMoreFingersNumbAfterUsingVibratingEquipment ? 'Yes' : 'No'
  },
  healthSurveillance.FingersWhiteWhenExposedToCold !== null && {
    dt: 'Have any of your fingers gone white* when exposed to cold?',
    dd: healthSurveillance.FingersWhiteWhenExposedToCold ? 'Yes' : 'No'
  },
  healthSurveillance.DifficultyGettingFingersWarm !== null && {
    dt: 'If yes, do you have difficulty getting them warm again?',
    dd: healthSurveillance.DifficultyGettingFingersWarm ? 'Yes' : 'No'
  },
  healthSurveillance.FingersWhiteAtAnyOtherTime !== null && {
    dt: 'Do your fingers go white at any other time?',
    dd: healthSurveillance.FingersWhiteAtAnyOtherTime ? 'Yes' : 'No'
  }
]
const healthQuestions2 = healthSurveillance => [
  healthSurveillance.AnyOtherProblemsWithMusclesOrJointsOfHandsArms !== null && {
    dt: 'Are you experiencing any other problems with the muscles or joints of the hands or arms?',
    dd: healthSurveillance.AnyOtherProblemsWithMusclesOrJointsOfHandsArms ? 'Yes' : 'No'
  },
  healthSurveillance.DifficultyPickingUpSmallItemsOrOpeningJars !== null && {
    dt:
      'Do you have difficulty picking up very small objects (such as screws or buttons) or opening tight jars?',
    dd: healthSurveillance.DifficultyPickingUpSmallItemsOrOpeningJars ? 'Yes' : 'No'
  },
  healthSurveillance.EverHadNeckArmOrHandInjuryOrOperation !== null && {
    dt: 'Have you ever had a neck, arm or hand injury or operation?',
    dd: healthSurveillance.EverHadNeckArmOrHandInjuryOrOperation ? 'Yes' : 'No'
  },
  healthSurveillance.EverHadNeckArmOrHandInjuryOrOperationDetails !== null && {
    dt: 'Please give more details of this',
    dd: healthSurveillance.EverHadNeckArmOrHandInjuryOrOperationDetails
  },
  healthSurveillance.AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVessels !== null && {
    dt: 'Have you ever had any serious diseases of your joints, skin, nerves, heart or blood vessels?',
    dd: healthSurveillance.AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVessels ? 'Yes' : 'No'
  },
  healthSurveillance.AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVesselsDetails !== null && {
    dt: 'Please give more details of this',
    dd: healthSurveillance.AnySeriousDiseasesOfJointsSkinNervesHeartOrBloodVesselsDetails
  },
  healthSurveillance.OnAnyLongTermMedication !== null && {
    dt: 'Are you on any long-term medication?',
    dd: healthSurveillance.OnAnyLongTermMedication ? 'Yes' : 'No'
  },
  healthSurveillance.OnAnyLongTermMedicationDetails !== null && {
    dt: 'Please give more details of this',
    dd: healthSurveillance.OnAnyLongTermMedicationDetails
  }
]

const occupationalHistoryDetail = ({ Address, Company, DateFrom, DateTo, JobTitle }) => [
  {
    dt: 'Date from:',
    dd: DateFrom || ''
  },
  {
    dt: 'Date to:',
    dd: DateTo || ''
  },
  {
    dt: 'Job title:',
    dd: JobTitle || ''
  },
  {
    dt: 'Company:',
    dd: Company || ''
  },
  {
    dt: 'Address:',
    dd: Address || ''
  }
]

const occupationalHistory = ({ NewStartHandArmVibrationExposures }) =>
  NewStartHandArmVibrationExposures &&
  NewStartHandArmVibrationExposures.map(item => {
    const { Address, Company, DateFrom, DateTo, JobTitle } = item
    return occupationalHistoryDetail({ Address, Company, DateFrom, DateTo, JobTitle })
  })

const OverviewCompleted = props => {
  const { id } = props
  // local states
  const [userDetails, setUserDetails] = useState({})
  const [userDocuments, setUserDocuments] = useState([])
  const [userDisclosure, setUserDisclosure] = useState([])
  const [userWageInformation, setUserWageInformation] = useState({})
  const [userLifeAssurance, setUserLifeAssurance] = useState([])
  const [userMedical, setUserMedical] = useState({})
  const [inviteSent, setInviteSent] = useState(false)
  const [accountLocked, setAccountLocked] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [healthSurveillance, setHealthSurveillance] = useState([])
  const [userApprentice, setUserApprentice] = useState([])
  const haveNotUsedToolsAndEquipments = healthSurveillance.EverUsedHandheldVibratingToolsInYourJob !== true
  
  // loaded values
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const promises = [
      api.newStart.getById,
      api.newStartDisclosure.getByUserId,
      api.newStartDocument.getByUserId,
      api.newStartWageInformation.getByUserId,
      api.newStartLifeAssurance.getByUserId,
      api.newStartMedicalForm.getByUserId,
      api.newStartHandArmVibration.getNewStartHandArmVibration,
      api.newStartApprentice.getByUserId
    ]

    // Enumerate All Promises And Ensure Any Errors Returned Do Not Cause Loading To Stop
    Promise.all(promises.map(p => p(id).catch(e => e))).then(responses => {
      const [
        userDetailsResponse,
        userDisclosureResponse,
        userDocumentsResponse,
        userWageInfoResponse,
        userLifeAssuranceResponse,
        userMedicalFormResponse,
        healthSurveillanceDetailResponse,
        userApprenticeResponse
      ] = responses

      if (userDetailsResponse.status === 200) {
        const { data } = userDetailsResponse
        setUserDetails(data)
        setCompleted(data.Steps[0].Completed)
        setAccountLocked(data.IsAccountLocked)
        setLoaded(true)
      }

      if (userDisclosureResponse.status === 200) {
        setUserDisclosure(userDisclosureResponse.data)
      }

      if (userDocumentsResponse.status === 200) {
        setUserDocuments(userDocumentsResponse.data)
      }

      if (userWageInfoResponse.status === 200) {
        setUserWageInformation(userWageInfoResponse.data)
      }

      if (userLifeAssuranceResponse.status === 200) {
        setUserLifeAssurance(userLifeAssuranceResponse.data.Beneficiaries)
      }

      if (userMedicalFormResponse.status === 200) {
        setUserMedical(userMedicalFormResponse.data)
      }

      if (healthSurveillanceDetailResponse.status === 200) {
        setHealthSurveillance(healthSurveillanceDetailResponse.data)
      }

      if (userApprenticeResponse.status === 200) {
        setUserApprentice(userApprenticeResponse.data)
      }
    })
  }, [])

  const isStepCompleted = (stepId) => {
    if (!userDetails) return null
    const foundStep = userDetails.Steps.find(step => step.StepId === stepId)
    return foundStep.Completed
  } 

  let list
  const healthQuestionnaire = {
    active: accessRole.allow(['recruitment', 'people', 'superAdmin', 'itDev', 'healthAndSafety']),
    title: 'Health Questionnaire',
    body: (
      <div>
        <DetailsList title="Health surveillance" listItems={healthSurveillanceDetail(healthSurveillance)} />
        {!haveNotUsedToolsAndEquipments && (
          <DetailsList title="Health Questions 1" listItems={healthQuestions1(healthSurveillance)} />
        )}
        {!haveNotUsedToolsAndEquipments && (
          <DetailsList title="Health Questions 2" listItems={healthQuestions2(healthSurveillance)} />
        )}
        {!haveNotUsedToolsAndEquipments && (
          <Base util={[border.a1, color.bcGrey3, padding.v4, padding.h3]}>
            {occupationalHistory(healthSurveillance).map((item, i) => (
              <DetailsList noborder key={i} title={`Occupational history ${i + 1}`} listItems={item} />
            ))}
          </Base>
        )}
      </div>
    )
  }
  if (!accessRole.isHealthAndSafetyUser()) {
    list = [
      {
        active: accessRole.allow(['recruitment', 'wages', 'people', 'superAdmin', 'itDev']),
        title: 'Details',
        body: (
          <div>
            <DetailsList
              title="Personal details"
              editLink={accessRole.deny('wages') && `/admin/new-start/${id}`}
              listItems={overviewDetailsPersonal(userDetails)}
            />
            <DetailsList
              title="Address details"
              editLink={accessRole.deny('wages') && `/admin/new-start/${id}`}
              listItems={loaded && overviewDetailsAddress(userDetails)}
            />
            <DetailsList
              title="Disclosure details"
              editLink={accessRole.deny('wages') && `/admin/new-start/${id}`}
              listItems={loaded && overviewDisclosureAdminDetails(userDetails)}
            />
            <DetailsList
              title="Contact details"
              editLink={!completed && accessRole.deny('wages') && `/admin/new-start/${id}`}
              listItems={loaded && overviewDetailsContactInfo(userDetails)}
            />
            <DetailsList
              title="Position details"
              editLink={accessRole.deny('wages') && `/admin/new-start/${id}/position-details`}
              listItems={loaded && overviewDetailsPosition(userDetails)}
            />
            <DetailsList
              title="Contract documents"
              editLink={accessRole.deny('wages') && `/admin/new-start/${id}/contracts`}
              listItems={loaded && overviewDetailsContractDocuments(userDocuments)}
            />
          </div>
        )
      },
      {
        active: accessRole.allow(['recruitment', 'wages', 'people', 'superAdmin', 'itDev']),
        title: 'Documents',
        body: (
          <div>
            {userDocuments.map((userDocument, i) => (
              <DetailsList
                key={i}
                title={`${userDocument.DocumentCategoryName} ${userDocument.DateSigned ? ' : Signed' : ''}`}
                listItems={overviewDocuments(userDocument)}
              />
            ))}
          </div>
        )
      },
      {
        active: accessRole.allow(['recruitment', 'superAdmin', 'itDev']),
        title: 'Disclosure',
        body: (
          <div>
            <DetailsList
              editLink={accessRole.isProfileEditor() && `/admin/new-start/${id}/disclosure/personal-details`}
              title="Personal details"
              listItems={overviewDisclosureDetails(userDisclosure)}
            />
            <DetailsList title="Birth details" listItems={overviewDisclosureBirthDetails(userDisclosure)} />
            <DetailsList
              title="Additional details"
              listItems={overviewDisclosureAdditionalDetails(userDisclosure)}
            />
            <DetailsList
              title="Contact information"
              listItems={overviewDisclosureContactInformation(userDisclosure)}
            />
            {userDetails.DisclosureServiceType === 1 && (
              <DetailsList
                title="Consent information"
                listItems={overviewDisclosureConsentDetails(userDisclosure)}
              />
            )}
            {loaded && userDetails.DisclosureServiceType === 1 && isStepCompleted(4) && !userDisclosure.DisclosureConsent  && (
              <Alert danger>
                These consents must be agreed by the candidate in order to complete the disclosure process
              </Alert>
            )}
          </div>
        )
      },
      {
        active: accessRole.allow(['recruitment', 'wages', 'superAdmin', 'itDev']),
        title: 'Wages',
        body: (
          <div>
            <DetailsList
              title="Wages information"
              listItems={overviewWageInformation({ ...userDetails, ...userWageInformation })}
            />
          </div>
        )
      },
      {
        active: accessRole.allow(['recruitment', 'people', 'superAdmin', 'itDev']),
        title: 'Apprentice',
        body: (
          <div>
            <DetailsList title="Apprentice" listItems={overviewApprentice(userApprentice)} />
          </div>
        )
      },
      {
        active: accessRole.allow(['recruitment', 'people', 'superAdmin', 'itDev']),
        title: 'Life assurance',
        body: (
          <div>
            {userLifeAssurance.map((lifeAssurance, i) => (
              <DetailsList
                key={i}
                title={`Beneficiary ${i + 1}`}
                listItems={overviewLifeAssurance(lifeAssurance)}
              />
            ))}
          </div>
        )
      },
      {
        active: accessRole.allow(['recruitment', 'people', 'superAdmin', 'itDev']),
        title: 'Medical',
        body: (
          <div>
            <DetailsList title="Medical information" listItems={overviewMedical(userMedical)} />
          </div>
        )
      },
      healthQuestionnaire
    ]
  } else {
    list = [healthQuestionnaire]
  }

  const handleSentInvite = e => {
    e.preventDefault()
    api.newStart.sendInvite(id).then(res => {
      if (res.status === 200) {
        setInviteSent(true)
      }
    })
  }

  const unlockAccount = e => {
    e.preventDefault()
    api.newStartAuth.unlock(id).then(res => {
      if (res.status === 200) {
        setAccountLocked(false)
      }
    })
  }

  const sendInviteButtonLabel = () => {
    let inviteButtonLabel
    if (completed) {
      if (inviteSent === true) {
        inviteButtonLabel = 'Invite sent'
        return inviteButtonLabel
      }
      if (inviteSent === false) {
        inviteButtonLabel = 'Resend invite'
        return inviteButtonLabel
      }
    } else {
      if (inviteSent === true) {
        inviteButtonLabel = 'Invite sent'
        return inviteButtonLabel
      }
      if (inviteSent === false) {
        inviteButtonLabel = 'Send invite'
        return inviteButtonLabel
      }
    }
  }

  return loaded ? (
    <Layout>
      {!completed && <Stepper steps={stepperSteps.newStart(id)} currentStep={4} />}

      <ContentWrap util={margin.t6}>
        <Text h1 util={scale.fs8}>
          {userDetails.Firstname} {userDetails.Surname}
        </Text>
      </ContentWrap>

      <ContentWrap>
        <TabList list={list} util={[border.a1, color.bcGrey3, padding.a5, shape.rounded, color.bgWhite]} />
        {!accessRole.isHealthAndSafetyUser() && (
          <>
            <Button
              success
              type="button"
              util={[position.floatRight, margin.t3]}
              onClick={() => navigate('/admin')}
            >
              {completed ? 'Update/return' : 'Complete'}
            </Button>

            <Button
              secondary
              type="button"
              util={[position.floatRight, margin.r2, margin.t3]}
              disabled={inviteSent}
              onClick={handleSentInvite}
            >
              {sendInviteButtonLabel()}
            </Button>
          </>
        )}
        {accessRole.allow(['recruitment', 'itDev']) && (
          <Button
            secondary
            util={[position.floatRight, margin.r2, margin.t3]}
            onClick={() => navigate(`/admin/new-start/${id}/viewInvite`)}
          >
            View Invite
          </Button>
        )}

        {accountLocked && (
          <Button danger util={[position.floatRight, margin.r2, margin.t3]} onClick={unlockAccount}>
            Unlock Account
          </Button>
        )}

        {accessRole.allow(['recruitment', 'itDev']) && (
          <Button
            danger
            util={[position.floatRight, margin.r2, margin.t3]}
            onClick={() => navigate(`/admin/new-start/${id}/delete`)}
          >
            Delete User
          </Button>
        )}
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

OverviewCompleted.propTypes = {
  id: PropTypes.string
}

export default OverviewCompleted
