import React from 'react'
import { UserTypes } from '../config/UserTypes'

export const UserContext = React.createContext({
  Id: '',
  AuthToken: '',

  Titles: [],
  Gender: [],
  AddressList: [],
  CountryList: [],

  Firstname: '',
  Surname: '',
  TitleId: '',
  Title: '',
  DateOfBirth: '',
  NINumber: '',
  UserType: UserTypes.UNKNOWN,
  TelNumber: '',
  EmailAddress: '',
  DateCreated: '',
  JobTitleId: '',
  JobTitleName: '',
  BranchId: '',
  BranchName: '',
  DepartmentId: '',
  DepartmentName: '',
  DisclosureFee: '',
  ManagerName: '',
  StartDate: '',
  Flexitime: '',
  FlexiType: '',
  WorkingLocationCategoryId: '',
  WorkingLocationCategoryName: '',
  RegionId: '',
  RegionName: '',
  Steps: [],
  Addresses: [
    {
      Line1: '',
      Line2: '',
      Town: '',
      Postcode: ''
    }
  ],
  ContactPreferences: [],
  WageInformation: {
    Id: '',
    UserId: '',
    EmployeeStatementType: '',
    EmployeeStatementTypeId: '',
    StudentBeforeSixthApril: '',
    HasUnpaidStudentLoan: '',
    StudentLoanPaidDirectToStudentLoanCompany: '',
    StudentLoanTypeId: '',
    HasUnpaidPostgradLoan: '',
    PostgradStudentBeforeSixthApril: '',
    PostgradLoanPaidDirectToStudentLoanCompany: '',
    SortCode: '',
    AccountNumber: '',
    AccountHolder: ''
  },
  Documents: [],
  DocumentItem: {},
  LifeAssurance: [],
  Medical: {
    NoDaysAbsentInLastTwelveMonths: '',
    HowManyPeriodsOfAbsence: '',
    DetailsOfEachAbsence: '',
    CirculatoryProblems: '',
    CirculatoryProblemsStartDate: '',
    CirculatoryProblemsEndDate: '',
    CirculatoryProblemsDetails: '',
    HeartProblems: '',
    HeartProblemsStartDate: '',
    HeartProblemsEndDate: '',
    HeartProblemsDetails: '',
    ChestProblems: '',
    ChestProblemsStartDate: '',
    ChestProblemsEndDate: '',
    ChestProblemsDetails: '',
    EpilepsyOrFainting: '',
    EpilepsyOrFaintingStartDate: '',
    EpilepsyOrFaintingEndDate: '',
    EpilepsyOrFaintingDetails: '',
    SkinDisorders: '',
    SkinDisordersStartDate: '',
    SkinDisordersEndDate: '',
    SkinDisordersDetails: '',
    OperationsOrFractures: '',
    OperationsOrFracturesStartDate: '',
    OperationsOrFracturesEndDate: '',
    OperationsOrFracturesDetails: '',
    AnyCurrentMedication: '',
    AnyCurrentMedicationStartDate: '',
    AnyCurrentMedicationDetails: '',
    BackArthritisRheumatism: '',
    BackArthritisRheumatismStartDate: '',
    BackArthritisRheumatismEndDate: '',
    BackArthritisRheumatismDetails: '',
    BonesJointsTendons: '',
    BonesJointsTendonsStartDate: '',
    BonesJointsTendonsEndDate: '',
    BonesJointsTendonsDetails: '',
    OtherSignificantHealthProblems: '',
    OtherSignificantHealthProblemsDetails: '',
    WorkedWithHighNoiseLevels: '',
    WorkedWithHighNoiseLevelsDetails: '',
    IndustrialInjuryClaim: '',
    IndustrialInjuryClaimDetails: '',
    AnyOtherConditions: '',
    ShareWithManagers: false,
    LoadedMedical: false
  },
  Disclosure: {
    Id: '',
    UserId: '',
    GenderId: '',
    GenderName: '',
    TitleId: '',
    TitleName: '',
    Forename: '',
    MiddleNames: '',
    Surname: '',
    KnownByOtherNames: null,
    OtherNames: [],
    DateOfBirth: '',
    TownOfBirth: '',
    CountryOfBirthId: null,
    UkNationalInsuranceNumber: '',
    HasPassport: '',
    PassportNumber: '',
    PassportCountryOfIssueId: null,
    HasDrivingLicence: '',
    DrivingLicenceNumber: '',
    DrivingLicenceCountryOfIssueId: null,
    DaytimePhoneNumber: '',
    EveningPhoneNumber: '',
    EmailAddress: '',
    AddressHistory: [],
    MothersMaidenName: '',
    HasNationalIdentityCard: false,
    NationalIdentityCardNumber: '',
    NationalIdentityCardCountryOfIssueId: null,
    NationalityId: null,
    NationalityName: '',
    DBSPrivacyPolicyConsent: false,
    DBSResultThirdPartyConsent: false,
    DBSArnoldClarkStoreConsent: false
  },
  Apprentice: {
    BootSize: '',
    OverallsSize: '',
    PoloShirtsSize: '',
    TravelRequired: '',
    TravelStation: '',
    AccommodationRequired: '',
    DietaryRequirements: ''
  },
  DisclosureApiValidationErrors: [],
  Loaded: '',
  // General
  handleUpdateStep: () => {},
  // Details
  handleChangePersonalDetails: () => {},
  handleSubmitPersonalDetails: () => {},
  handleAddressSearch: () => {},
  handleChangeAddressDetails: () => {},
  handleAddressSelect: () => {},
  // Documents
  handleViewDocument: () => {},
  handleSubmitAcceptOffer: () => {},
  // Disclosure
  handleLoadDisclosure: () => {},
  handleChangeDisclosure: () => {},
  handleAddDisclosureOtherNames: () => {},
  handleRemoveDisclosureOtherName: () => {},
  handleSubmitDisclosure: () => {},
  handleDisclosureCompleteStep: () => {},
  handleDisclosureAddToAddressHistory: () => {},
  handleDisclosureRemoveFromAddressHistory: () => {},
  handleDisclosureAddAddress: () => {},
  // Wages
  handleChangeWageInformation: () => {},
  handleSubmitWageInformation: () => {},
  // Life assurance
  handleSubmitLifeAssurance: () => {},
  handleAddLifeAssuranceBeneficiary: () => {},
  handleDeleteLifeAssurance: () => {},
  // Medical
  handleLoadMedical: () => {},
  handleChangeMedical: () => {},
  handleSubmitMedical: () => {},
  handleChangeApprentice: () => {},
  // HAVS
  handleUpdateHandArmVibration: () => {}
})

export const UserProvider = UserContext.Provider
export const UserConsumer = UserContext.Consumer
