import * as yup from 'yup'
import '../../../../utilities/toUTC'

export const medicalInsuranceReportSchema = yup.object().shape({
  StartDate: yup
    .date()
    .toUTC()
    .typeError('Please check required date format')
    .required('This field is required'),
  EndDate: yup
    .date()
    .toUTC()
    .typeError('Please check required date format')
    .required('This field is required')
})