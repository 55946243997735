import * as yup from 'yup'
import '../../../utilities/toUTC'
import moment from 'moment'
import { doesPostcodeMatchDisclosureServiceType } from '../../../utilities/validateDisclosureType'

export const generatePersonalDetailsSchema = () =>
  yup.object().shape({
    TitleId: yup
      .number()
      .positive('This field is required')
      .required('This field is required')
      .typeError('This field is required')
      .nullable(),
    Firstname: yup
      .string()
      .required('This field is required')
      .nullable(),
    Surname: yup
      .string()
      .required('This field is required')
      .nullable(),
    DateOfBirth: yup
      .string()
      .required('This field is required')
      .nullable(),
    NINumber: yup
      .string()
      .matches(/([A-CEGHJ-NOPR-TW-Z]{2}[0-9]{6}[ABCD\s]{1})/, 'NI Number must be in the correct format', {
        excludeEmptyString: true
      })
      .required('This field is required')
      .nullable(),
    Postcode: yup
      .string()
      .matches(
        /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/,
        'This field must be a UK Postcode format',
        { excludeEmptyString: true }
      )
      .required('This field is required')
      .nullable()
      .test({
        name: 'TestDisclosureTypeMatchesPostcode',
        test(postCodeValue) {
          if (postCodeValue && this.parent.DisclosureServiceType) {
            return doesPostcodeMatchDisclosureServiceType(postCodeValue, this.parent.DisclosureServiceType)
          }
          return true
        },
        message: 'Postcode and disclosure service must match'
      }),
    Line1: yup
      .string()
      .required('This field is required')
      .nullable(),
    Town: yup
      .string()
      .required('This field is required')
      .nullable(),
    DisclosureServiceType: yup
      .string()
      .required('This field is required')
      .oneOf(['1', '2'])
      .test({
        name: 'TestDisclosureTypeMatchesPostcode',
        test(disclosureServiceTypeValue) {
          if (this.parent.Postcode && disclosureServiceTypeValue) {
            return doesPostcodeMatchDisclosureServiceType(this.parent.Postcode, disclosureServiceTypeValue)
          }
          return true
        },
        message: 'Disclosure service and postcode must match'
      }),
    DisclosureFee: yup.string().required('This field is required'),
    TelNumber: yup
      .string()
      .matches(/^\+\d{1,3}/, {
        excludeEmptyString: true,
        message: 'This field must start with a country dialling code. e.g. +44'
      })
      .matches(/^\+?\d+$/, {
        excludeEmptyString: true,
        message: 'This field can only contain the "+" sign and digits'
      })
      .min(6, 'This field must have at least 6 characters')
      .max(13, 'This field must have no more than 13 characters')
      .required('This field is required')
      .nullable(),
    EmailAddress: yup
      .string()
      .email('This field must be an email address')
      .required('This field is required')
      .nullable(),
    UserType: yup
      .number()
      .required('This field is required')
      .nullable()
  })

export const positionDetailsSchema = yup.object().shape({
  JobTitleId: yup
    .number()
    .positive('This field is required')
    .typeError('This field is required'),
  BranchId: yup
    .number()
    .positive('This field is required')
    .typeError('This field is required'),
  DepartmentId: yup
    .number()
    .positive('This field is required')
    .typeError('This field is required'),
  WorkingLocationCategoryId: yup.number().typeError('This field is required'),
  RegionId: yup
    .number()
    .positive('This field is required')
    .typeError('This field is required'),
  StartDate: yup
    .date()
    .toUTC()
    .typeError('Please check required date format')
    .required('This field is required'),
  HoursOfWork: yup
    .string()
    .nullable()
    .max(100, 'This field must contain no more than 100 characters'),
  ContractTypeId: yup
    .number()
    .positive()
    .typeError('This field is required'),
  WorkTypeId: yup
    .number()
    .positive()
    .typeError('This field is required'),
  TemporaryContractReasonId: yup
    .number()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .when('WorkTypeId', {
      is: 2,
      then: yup.number().required('This field is required'),
      otherwise: yup.number().nullable()
    }),
  TemporaryContractExpiryDate: yup
    .date()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .when('WorkTypeId', {
      is: 2,
      then: yup
        .date()
        .toUTC()
        .min(yup.ref('StartDate'), 'Date must be after start date')
        .required('This field is required'),
      otherwise: yup
        .date()
        .nullable()
        .notRequired()
    }),
  Flexitime: yup
    .string()
    .required('This field is required')
    .nullable(),
  ManagerId: yup
    .number()
    .required('This field is required')
    .positive('This field is required')
    .typeError('This field is required')
})

export const contractsSchema = yup
  .array()
  .test({
    name: 'Contract',
    test: values => values.map(item => +item.DocumentTypeId).includes(1),
    message: 'Must have a contract',
    exclusive: false
  })
  .test({
    name: 'Offer letter',
    test: values => values.map(item => +item.DocumentTypeId).includes(2),
    message: 'Must have an offer letter',
    exclusive: false
  })
  .test({
    name: 'Check filetypes',
    test: values => {
      const fileArray = ['image/jpeg', 'image/png', 'application/pdf']
      return values.every(element => fileArray.includes(element.DocumentFileType))
    },
    message: 'Must be .jpg, .png or .pdf file type',
    exclusive: false
  })
  .of(
    yup.object().shape({
      Title: yup.string().required('Title is required'),
      DocumentTypeId: yup.string().required('Document type is required')
    })
  )

export const deleteUserSchema = yup.object().shape({
  Reason: yup.string().required('Please select a removal reason'),
  Comment: yup.string().required('Please enter further information')
})

export const disclosureConsentSchema = yup.object().shape({
  DBSPrivacyPolicyConsent: yup
    .boolean()
    .required(
      'Please confirm you have read and accept the Privacy Policy and how DBS will process my personal data'
    )
    .oneOf(
      [true],
      'Please confirm you have read and accept the Privacy Policy and how DBS will process my personal data'
    ),
  DBSResultThirdPartyConsent: yup
    .boolean()
    .required(
      'Please confirm you agree to receiving e-result and sharing with necessary third parties involved in recruitment process'
    )
    .oneOf(
      [true],
      'Please confirm you agree to receiving e-result and sharing with necessary third parties involved in recruitment process'
    ),
  DBSArnoldClarkStoreConsent: yup
    .boolean()
    .required('Please confirm you agree to Arnold Clark holding your data for 1 year')
    .oneOf([true], 'Please confirm you agree to Arnold Clark holding your data for 1 year')
})
