import React, { useState, useEffect } from 'react'
import { Grid, Form, Button, Table, margin, width, position, Text } from 'chassis-react'
import moment from 'moment'

import api from '../../../../api'

import ContentLoader from '../../../../components/ContentLoader'
import Layout from '../../../../components/Layout'
import FormTextInput from '../../../../components/FormTextInput'

import { medicalInsuranceReportSchema } from './schema'
import getErrorForField from '../../../../utilities/getErrorForField'
import handleBlur from '../../../../utilities/handleBlur'
import valueFormat from '../../../../utilities/valueFormat'

import './style.scss'
import accessRole from '../../../../services/accessRole'

const MedicalInsuranceReport = () => {
  const [loaded, setLoaded] = useState(false)
  const [axaReportData, setAxaReportData] = useState([])

  const [formData, setFormData] = useState({ 
    StartDate: moment.utc().subtract(2,'M').format('YYYY-MM-DD'),
    EndDate: moment.utc().add(2,'M').format('YYYY-MM-DD')
  })  
 
  const [validationErrors, setValidationErrors] = useState([])

  useEffect(() => {
    ;(async () => {
      const request = {
        StartDate: formData.StartDate,
        EndDate: formData.EndDate
      }
  
      api.report.medicalInsuranceReportList(request).then(response => {
        setAxaReportData(response.data)
      })
      setLoaded(true)
    })()
  },[])

  const updateFormData = (name, value) => {
    setFormData(existing => ({ ...existing, [name]: value }))
  }

  const handleChange = e => {
    const {
      value,
      name,
      dataset: { format }
    } = e.target

    updateFormData(name, valueFormat(format, value))
  }

  const handleDataFilterCall = () => {
    const request = {
      StartDate: formData.StartDate,
      EndDate: formData.EndDate
    }

    api.report.medicalInsuranceReportList(request).then(response => {
      setAxaReportData(response.data)
    })
    setLoaded(true)
  }

  const handleDownloadReport = () => {
    const request = {
      StartDate: formData.StartDate,
      EndDate: formData.EndDate
    }  
    api.report.medicalInsuranceReportCsv(request).then(({ data }) => {
      const d = new Date()
      const filename = `report-${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}.csv`

      const hiddenElement = document.createElement('a')
      hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(data)}`
      hiddenElement.target = '_blank'
      hiddenElement.download = filename
      document.body.appendChild(hiddenElement)
      hiddenElement.click()
      hiddenElement.remove()
    })
  }

  return loaded ? (
    <Layout>      
      <Grid container>
        <Grid row>
          <Grid colMd={8}>
            <Text h1 util={[margin.v4]}>
              AXA Medical Insurance Opt Out Report
            </Text>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Form inline util={[margin.v4]} onSubmit={e => e.preventDefault()}>
          <Form group>
            <FormTextInput
              name="StartDate"
              type="date"
              label="Start date *"
              value={formData.StartDate}
              min={moment
                .utc()
                .subtract(6, 'M')
                .format('YYYY-MM-DD')}
              max={moment
                .utc()
                .add(10, 'Y')
                .format('YYYY-MM-DD')}
              util={width.w8}
              placeholder="yyyy-mm-dd"
              pattern="\d{4}-\d{2}-\d{2}"
              error={getErrorForField('StartDate', validationErrors)}
              onChange={handleChange}
              onBlur={e => handleBlur(e, medicalInsuranceReportSchema, formData, setValidationErrors)}
            />
          </Form>
          <Form group>
            <FormTextInput
              name="EndDate"
              type="date"
              label="End date *"
              value={formData.EndDate}
              min={moment
                .utc()
                .subtract(6, 'M')
                .format('YYYY-MM-DD')}
              max={moment
                .utc()
                .add(10, 'Y')
                .format('YYYY-MM-DD')}
              util={width.w8}
              placeholder="yyyy-mm-dd"
              pattern="\d{4}-\d{2}-\d{2}"
              error={getErrorForField('EndDate', validationErrors)}
              onChange={handleChange}
              onBlur={e => handleBlur(e, medicalInsuranceReportSchema, formData, setValidationErrors)}
            />
          </Form>
          <Button success onClick={handleDataFilterCall} util={margin.l2}>
                Filter
              </Button>
          
          <Form group util={position.floatRightSm}>
            {accessRole.isMedicalInsuranceViewer() && (
              <Button success onClick={handleDownloadReport} util={margin.l2}>
                Download Report
              </Button>
            )}
          </Form>
        </Form>

        <Table bordered striped util={width.w12}>
          <Table head>
            <Table row>
              <Table header>NI Number</Table>
              <Table header>Start Date</Table>
              <Table header>Opt Out</Table>
              <Table header>Status</Table>
              <Table header>Dependent 1</Table>
              <Table header>Dependent 2</Table>
              <Table header>Dependent 3</Table>
              <Table header>Dependent 4</Table>
              <Table header>Dependent 5</Table>
              <Table header>Dependent 6</Table>
              <Table header>Dependent 7</Table>
              <Table header>Dependent 8</Table>
            </Table>
          </Table>

          <Table body id="axaReport">
            {axaReportData.map((item, i) => (
              <Table row key={i}>
                <Table cell>{item.NationalInsuranceNumber}</Table>
                <Table cell>{item.StartDate}</Table>
                <Table cell>{item.MedicalInsuranceOptOut}</Table>
                <Table cell>{item.Status}</Table>
                <Table cell>{item.MedicalInsuranceDependent1}</Table>
                <Table cell>{item.MedicalInsuranceDependent2}</Table>
                <Table cell>{item.MedicalInsuranceDependent3}</Table>
                <Table cell>{item.MedicalInsuranceDependent4}</Table>
                <Table cell>{item.MedicalInsuranceDependent5}</Table>
                <Table cell>{item.MedicalInsuranceDependent6}</Table>
                <Table cell>{item.MedicalInsuranceDependent7}</Table>
                <Table cell>{item.MedicalInsuranceDependent8}</Table>
              </Table>
            ))}
          </Table>
        </Table>
      </Grid>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

export default MedicalInsuranceReport
