import moment from 'moment'
import api from '../api'

const adminUserService = {
  personalDetails: data => {
    const state = {
      UserId: data.Id || 0,
      TitleId: data.TitleId,
      Title: data.Title,
      Firstname: data.Firstname,
      Surname: data.Surname,
      DateOfBirth: moment.utc(data.DateOfBirth),
      NINumber: data.NINumber,
      PassportExpiry: data.PassportExpiry ? moment.utc(data.PassportExpiry) : null,
      UserType: data.UserType,
      ReferralCode: data.ReferralCode,
      Addresses: [
        {
          Line1: data.Line1,
          Line2: data.Line2,
          Town: data.Town,
          Postcode: data.Postcode
        }
      ],
      DisclosureServiceType: +data.DisclosureServiceType,
      DisclosureFee: +data.DisclosureFee,
      TelNumber: data.TelNumber,
      EmailAddress: data.EmailAddress
    }
    
    if (state.UserId) {
      return api.newStartPersonalDetails.updateDetails(state)
    }

    return api.newStartPersonalDetails.addDetails(state)
  },

  positionDetails: data => {
    const state = {
      UserId: data.UserId,
      JobTitleId: parseInt(data.JobTitleId, 10),
      BranchId: parseInt(data.BranchId, 10),
      DepartmentId: parseInt(data.DepartmentId, 10),
      WorkingLocationCategoryId: parseInt(data.WorkingLocationCategoryId, 10),
      RegionId: parseInt(data.RegionId, 10),
      ManagerName: data.ManagerName,
      StartDate: data.StartDate,
      HoursOfWork: data.HoursOfWork,
      Flexitime: data.Flexitime,
      ContractTypeId: data.ContractTypeId,
      WorkTypeId: data.WorkTypeId,
      TemporaryContractReasonId: data.TemporaryContractReasonId,
      TemporaryContractExpiryDate: data.TemporaryContractExpiryDate,
      CompanyCar: data.CompanyCar,
      ManagerId: data.ManagerId
    }
    return api.newStartPositionDetails.updateDetails(state)
  },

  contracts: (values, Id) => {
    values.forEach(item => {
      if (item.Id) {
        const state = {
          DocumentId: item.Id,
          Title: item.Title,
          DocumentTypeId: item.DocumentTypeId
        }
        return api.newStartDocument.updateDoc(state)
      }
      const state = {
        UserId: Id,
        Title: item.Title,
        Filename: item.Filename,
        Document: item.Document,
        UploadedDate: item.UploadedDate,
        UploadedBy: item.UploadedBy,
        DocumentTypeId: item.DocumentTypeId,
        DocumentFileType: item.DocumentFileType
      }
      return api.newStartDocument.addNewDoc(state)
    })
  },

  deleteContracts: values => {
    values.forEach(value => {
      if (value !== null) {
        return api.newStartDocument.deleteByDocId(value)
      }
      return null
    })
  }
}

export default adminUserService
