import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import { Button, Grid, Text, margin, position, text } from 'chassis-react'

import api from '../../../api'

import splitCamelCase from '../../../utilities/splitCamelCase'
import valueFormat from '../../../utilities/valueFormat'

import adminUserService from '../../../services/adminUserService'

import stepperSteps from '../../../config/stepperSteps'

import ContentLoader from '../../../components/ContentLoader'
import ContentWrap from '../../../components/ContentWrap'
import DocumentUpload from '../../../components/DocumentUpload'
import FormSelectInput from '../../../components/FormSelectInput'
import FormTextInput from '../../../components/FormTextInput'
import FormWrapper from '../../../components/FormWrapper'
import Layout from '../../../components/Layout'
import Modal from '../../../components/Modal'
import Stepper from '../../../components/Stepper'
import ValidationAlertList from '../../../components/ValidationAlertList'

import { contractsSchema } from './schema'

const Contracts = ({ id }) => {
  const [loaded, setLoaded] = useState(false)
  const [documentCategories, setDocumentCategories] = useState([])
  const [contractDocuments, setContractDocuments] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [documentRemove, setDocumentRemove] = useState([])
  const [validationErrors, setValidationErrors] = useState([])
  const [completed, setCompleted] = useState(false)
  const [hideSubmit, setHideSubmit] = useState(false)

  useEffect(() => {
    ;(async () => {
      await api.newStart.getById(id).then(({ data }) => setCompleted(data.Steps[0].Completed))
      await api.documentCategory.getAll().then(({ data }) => setDocumentCategories(data))
      await api.newStartDocument
        .getByUserId(id)
        .then(({ data }) => setContractDocuments(data))
        .catch(error => error && setLoaded(true))
      setLoaded(true)
    })()
  }, [])

  const handleUpload = e => {
    setValidationErrors([])
    // map upload data to object
    e.forEach(item => {
      const newContractDocument = {
        UserId: id,
        Title: item.name,
        Filename: item.name,
        Document: item.base64.split('base64,')[1],
        UploadedDate: new Date(),
        UploadedBy: '',
        DocumentFileType: item.file.type,
        DocumentRemove: []
      }
      if (item.file.size >= 3000000) {
        setValidationErrors([{ message: 'File can’t be larger than 3mb' }])
      } else {
        // push the  new newContractDocument array to ContractDocuments state
        setContractDocuments([...contractDocuments, newContractDocument])
      }
    })
  }

  const handleChange = e => {
    e.preventDefault()
    // grab the location of the item from the name and also the input name
    const {
      value,
      name,
      dataset: { format }
    } = e.target

    const field = name.split('.')
    const objectPosition = field[1] // the position
    const objectItem = field[2] // the input name

    // set the change to the correct item on change
    const documentsCopy = [...contractDocuments]
    documentsCopy[objectPosition][objectItem] = valueFormat(format, value)
    setContractDocuments(documentsCopy)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setHideSubmit(true)
    // run the array through validation
    contractsSchema
      .validate(contractDocuments, { abortEarly: false })
      .then(response => {
        adminUserService.contracts(response, id)
        adminUserService.deleteContracts(documentRemove)

        setDocumentRemove([])

        setTimeout(() => {
          navigate(`/admin/new-start/${id}/overview`)
        }, 500)
      })
      .catch(error => {
        setValidationErrors(error.inner)
        setHideSubmit(false)
      })
  }

  const handleView = (e, data) => {
    e.preventDefault()
    const toggleModal = () => setShowModal(!showModal)

    if (data.Id) {
      // fetch the base64 data
      api.newStartDocument.getByDocId(data.Id).then(res => {
        setModalData(res.data)
        toggleModal()
      })
    } else {
      // use the current base64 data
      setModalData(data)
      toggleModal()
    }
  }

  const handleDelete = (e, data, i) => {
    e.preventDefault()
    // remove the item from the state
    const documentsCopy = [...contractDocuments]
    documentsCopy.splice(i, 1)
    setContractDocuments(documentsCopy)

    // remove the item from the database
    if (data.Id) {
      setDocumentRemove([...documentRemove, data.Id])
    }
  }

  return loaded ? (
    <Layout>
      <ValidationAlertList errors={validationErrors} />

      {!completed && <Stepper steps={stepperSteps.newStart(id)} currentStep={3} />}

      <ContentWrap slim>
        <form onSubmit={handleSubmit}>
          <FormWrapper>
            <Text h3>Upload documents</Text>
            <Text>Max size: 3Mb (.jpg, .png, .pdf)</Text>

            <DocumentUpload onDone={handleUpload} />

            {contractDocuments.length > 0 ? (
              <div>
                <Text h3 util={margin.t5}>
                  Documents
                </Text>

                {contractDocuments.map((item, i) => (
                  <div className="documentRow" key={i}>
                    <Grid row util={margin.t2}>
                      <Grid colSm={8}>
                        <Grid row>
                          <Grid colSm={6}>
                            <FormTextInput
                              name={`contractDocuments.${i}.Title`}
                              value={item.Title}
                              onChange={handleChange}
                            />
                          </Grid>

                          <Grid colSm={6}>
                            <FormSelectInput
                              name={`contractDocuments.${i}.DocumentTypeId`}
                              value={item.DocumentTypeId}
                              onChange={handleChange}
                              data-format="int"
                            >
                              <option />
                              {documentCategories.map(category => (
                                <option key={category.Name} value={category.Id}>
                                  {splitCamelCase(category.Name)}
                                </option>
                              ))}
                            </FormSelectInput>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid colSm={4}>
                        <Grid row>
                          <Grid colSm={6}>
                            <Button block danger onClick={e => handleDelete(e, contractDocuments[i], i)}>
                              delete
                            </Button>
                          </Grid>
                          <Grid colSm={6}>
                            <Button block onClick={e => handleView(e, contractDocuments[i])}>
                              View
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {showModal && (
                      <Modal onClick={() => setShowModal(!showModal)}>
                        {// check if it's an image or a pdf
                        modalData.DocumentFileType.split('/')[1] === 'pdf' && (
                          <object
                            data={`data:${modalData.DocumentFileType};base64,${modalData.Document}`}
                            type={modalData.DocumentFileType}
                            width="100%"
                            height="100%"
                          >
                            This browser does not support inline PDFs. Please download the PDF to view it:{' '}
                            <a href={modalData.Document}>Download PDF</a>
                          </object>
                        )}
                        {modalData.DocumentFileType.split('/')[0] === 'image' && (
                          <img
                            src={`data:${modalData.DocumentFileType};base64,${modalData.Document}`}
                            alt={modalData.Title}
                          />
                        )}
                      </Modal>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <Text util={[margin.t5, text.center]}>There are currently no documents to view.</Text>
            )}
          </FormWrapper>

          <Button success type="submit" util={position.floatRight} disabled={hideSubmit}>
            {completed ? 'Update details' : 'Next step'}
          </Button>
        </form>
      </ContentWrap>
    </Layout>
  ) : (
    <ContentLoader />
  )
}

Contracts.propTypes = { id: PropTypes.string }

export default Contracts
